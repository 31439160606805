import { useEffect, useRef, useState } from "react";

// @mui
import { LoadingButton } from "@mui/lab";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
// routes
// import { PATH_AUTH } from "../../routes/paths";
// hooks
// import useAuth from "../../hooks/useAuth";
// import useIsMountedRef from "../../hooks/useIsMountedRef";
// components
// import Iconify from "../../components/Ic/onify";
// import {
//     FormProvider,
//     RHFTextField,
//     RHFCheckbox,
// } from "../../components/hook-form";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useSnackbar } from "notistack";
import { eduleyApi } from "src/api/apiConfig";
import { MEDIA_VIDEO, MEDIA_VIDEO_TOURL } from "src/apiUrl";
import Label from "src/components/Label";
import UploadVideo from "src/components/upload/UploadVideo";
import {
  getVideo
} from "src/redux/slices/media";
import { useDispatch, useSelector } from "src/redux/store";
import PublicPrivate from "../component/PublicPrivate";
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 220,
  fontSize: 18,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.main,
  },
}));

const VideoTypeMedia = ({ loading, afterSubmit, value }) => {
  const [mediatype, setMediaType] = useState("");

  useEffect(() => {
    setMediaType(value.media_type);
  }, [value]);

  return (
    <>
      <MediaType
        mediatype={mediatype}
        setMediaType={setMediaType}
        value={value}
      />

      {mediatype === "url" && (
        <VideoMediaTypeUrl
          filetype="video"
          afterSubmit={afterSubmit}
          value={value}
        />
      )}

      {mediatype === "uploaded" && (
        <Uploaded
          filetype="video"
          loading={loading}
          afterSubmit={afterSubmit}
          value={value}
        />
      )}
    </>
  );
};

export default VideoTypeMedia;

const Uploaded = ({ filetype, afterSubmit, value: value2 }) => {
  const fileInputRef = useRef(null);
  const [value, setValue] = useState({ is_private: false });
  const [error, setError] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  // const [attechmentdata, setAttechmentData] = useState({})
  const dispatch = useDispatch();
  useEffect(() => {
    if (value2.name) {
      setValue(value2);
    }
  }, [value2]);
  const [loading, setLoading] = useState(false);
  const [progressdata, setProgressdata] = useState(0);

  const config3 = {
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgressdata(percentCompleted);
      console.log(percentCompleted);
    },
  };
  const handleUpdate = async () => {
    var filedata = new FormData();
    let v = { ...value };
    delete v.url;

    let res = "";
    if (value2.media_file !== value.media_file) {
      // for (const x in v) {
      //     filedata.append(x, v[x]);
      // }
      // filedata.append("media_type", "uploaded");
      setLoading(true);
      let filename = {
        file_name: value?.media_file?.name,
        is_private: value.is_private,
      };
      let attechmentdata = {};

      // if (value2.file_type === "video") {
      //     res = await dispatch(updateVideo(filedata, value2.id));
      // }
      try {
        const response = await eduleyApi.post(`${MEDIA_VIDEO_TOURL}`, filename);
        attechmentdata = response.data.data;
        // console.log('setattechment data--------', response.data.data)
      } catch (error) {
        // dispatch(slice.actions.hasError(error));
        // return "error";
      }
      let data = { ...attechmentdata?.url?.fields, file: value?.media_file };

      var filedata = new FormData();
      for (const x in data) {
        console.log(x, data[x]);
        filedata.append(x, data[x]);
      }
      // create aws url

      console.log("va---", value2);

      // filedata.append("media_type", "uploaded");
      let res = "";
      try {
        const response = await axios.post(
          `${attechmentdata.url.url}`,
          filedata,
          config3
        );

        console.log("respose------", response);
      } catch (error) {}
      let dataforthird = {
        ...v,
        media_file: attechmentdata.url.fields.key,
        media_type: "uploaded",
        // is_private: value.is_private,
      };
      // delete dataforthird["is_private"];
      var filedata2 = new FormData();
      for (const x in dataforthird) {
        console.log(x, dataforthird[x]);
        filedata2.append(x, dataforthird[x]);
      }
      try {
        const response2 = await eduleyApi.patch(
          `${MEDIA_VIDEO}${value2?.id}/`,
          filedata2
        );
        afterSubmit();
      } catch (error) {
        if (error?.data?.data?.non_field_errors) {
          let er = error?.data?.data?.non_field_errors;
          for (let index = 0; index < er.length; index++) {
            const element = er[index];
            enqueueSnackbar(element, { variant: "error" });
          }
        }
      }
      setLoading(false);
    } else {
      // filedata = { ...value };
      // delete filedata["is_private"];
      let d2 = { ...value };
      delete d2.media_file;
      for (const x in d2) {
        // console.log(x, value[x]);
        filedata.append(x, d2[x]);
      }
      try {
        const response2 = await eduleyApi.patch(
          `${MEDIA_VIDEO}${value2?.id}/`,
          filedata
        );
        afterSubmit();
      } catch (error) {
        if (error?.data?.data?.non_field_errors) {
          let er = error?.data?.data?.non_field_errors;
          for (let index = 0; index < er.length; index++) {
            const element = er[index];
            enqueueSnackbar(element, { variant: "error" });
          }
        }
      }
      // updateVideolink(filedata)
    }
    // console.log("data--", filedata);
    if (res === "success") {
      afterSubmit();
    }
  };

  const hasExtension = function (exts, fileName) {
    var regx = new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$");
    var res = regx.test(fileName);
    return res;
  };

  const handleSubmit = async () => {
    // let data = { ...attechmentdata.url.fields, file: value?.media_file };
    // let data = { file: value?.media_file, ...attechmentdata.fields };
    // console.log("data--", data, attechmentdata.url);
    // return
    if (value2.id) {
      handleUpdate();
      return;
    }
    // console.log("data--", data);

    if (!value.name) {
      setError({ ...error, name: "Please enter a title" });
      return;
    }
    if (!value.media_file) {
      setError({ ...error, media_file: "Please upload a file" });
      return;
    }

    if (filetype === "video") {
      if (
        !hasExtension(
          [
            ".mp4",
            ".mkv",
            ".MKV",
            ".MP4",
            ".avi",
            ".AVI",
            ".mov",
            ".MOV",
            ".wmv",
            ".WMV",
          ],
          value.media_file.path
        )
      ) {
        setError({ ...error, media_file: "Please upload a valid video file." });
        return;
      }
    }
    setLoading(true);
    let filename = {
      file_name: value?.media_file?.name,
      is_private: value.is_private,
    };
    let attechmentdata = {};

    try {
      const response = await eduleyApi.post(`${MEDIA_VIDEO_TOURL}`, filename);
      attechmentdata = response.data.data;
      // console.log('setattechment data--------', response.data.data)
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      // return "error";
    }
    let data = { ...attechmentdata?.url?.fields, file: value?.media_file };
    var filedata = new FormData();
    for (const x in data) {
      console.log(x, data[x]);
      filedata.append(x, data[x]);
    }
    // create aws url

    // filedata.append("media_type", "uploaded");
    let res = "";
    try {
      const response = await axios.post(
        `${attechmentdata.url.url}`,
        filedata,
        config3
      );

      console.log("respose------", response);
    } catch (error) {}
    let dataforthird = {
      ...value,
      media_file: attechmentdata.url.fields.key,
      media_type: "uploaded",
    };
    try {
      const response = await eduleyApi.post(`${MEDIA_VIDEO}`, dataforthird);
      afterSubmit();
    } catch (error) {}
    setLoading(false);
  };

  const getAttechmentdata = async () => {};

  const setfiles = (e) => {
    console.log("e", e);
    if (e != undefined) {
      setValue({ ...value, media_file: e });
    } else {
      setValue({ ...value, media_file: false });
    }
  };

  console.log("value-----", value?.media_file?.name);

  return (
    <>
      <PublicPrivate
        edit={!!value2.name}
        value={value}
        setType={(o) => setValue({ ...value, is_private: o })}
      />
      <Stack spacing={3}>
        <InputField
          label="Title"
          value={value.name || value2.name}
          // error={error.name}
          setValue={(e) => setValue({ ...value, name: e })}
          setErrorText={(e) => {
            setError({ ...error, name: e ? "" : "Please enter a Title" });
          }}
        />
      </Stack>
      <Stack>{error.name && <Label color={"error"}>{error.name}</Label>}</Stack>
      <Stack spacing={3} direction="row" sx={{ mt: 2, mb: 2 }}>
        <LabelStyle sx={{ mt: 2 }}>Choose Media</LabelStyle>
        <UploadVideo
          accept="video/*"
          setfile={(e) => setfiles(e)}
          file={value.media_file}
          // error={checkError}
          helperText={
            <Typography
              variant="caption"
              sx={{
                mt: 2,
                mx: "auto",
                display: "block",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              {/* Allowed *.png, *.svg
                            <br /> max size of {fData(307200)} */}
            </Typography>
          }
          // {...other}
        />
        {/* <MediaAttachment
                    filetype={filetype}
                    attachments={value2.media_file}
                    setfiles={(p) => setfiles(p)}
                /> */}
      </Stack>
      <Stack>
        {error.media_file && (
          <Label color={"warning"}>{error.media_file}</Label>
        )}
      </Stack>
      {/* <Button onClick={() => getAttechmentdata()}>get attec</Button> */}
      {loading && (
        <BorderLinearProgress
          variant="determinate"
          sx={{ mt: 2, mb: 2 }}
          // valueBuffer={56}
          color="primary"
          value={progressdata}
        />
      )}
      <Grid container spacing={2}>
        <Grid item md={6} sx={{ display: "flex" }}>
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{ mr: 1 }}
            // loadingIndicator={<CircularProgress value={progressdata} color="inherit" size={16} />}
            onClick={() => {
              if (!loading) {
                handleSubmit();
              }
            }}
            // disabled={loading}
          >
            {loading ? "Please Wait! Uploading" : "Submit"}{" "}
          </LoadingButton>
          {loading && (
            <Typography variant="h4" color="primary">
              {progressdata}%
            </Typography>
          )}
        </Grid>
        <Grid item md={6}></Grid>
      </Grid>
    </>
  );
};

const VideoMediaTypeUrl = ({ afterSubmit, value: value2 }) => {
  const [videotype, setVideoType] = useState("");
  const [value, setValue] = useState({});

  console.log("value2", value2);
  const dispatch = useDispatch();
  const { video } = useSelector((state) => state.media);
  const [error, setError] = useState({});
  console.log("video", video);
  const [loading, setLoading] = useState(false);
  const [progressdata, setProgressdata] = useState(0);

  const config3 = {
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgressdata(percentCompleted);
      console.log(percentCompleted);
    },
  };
  useEffect(() => {
    if (value2.id) {
      dispatch(getVideo(value2.id));
    }
    if (value2.id) {
      setVideoType(value2.video_type);
    }
  }, [value2]);

  useEffect(() => {
    if (value2.id) {
      setValue(video);
      setVideoType(video.video_type);
    }
  }, [video]);

  const handleUpdate = async (data) => {
    updateVideolink(data, data.id);
  };

  const updateVideolink = async (file, id) => {
    setLoading(true);
    try {
      const response = await eduleyApi.patch(
        `${MEDIA_VIDEO}${id}/`,
        file,
        config3
      );
      afterSubmit();
    } catch (error) {}
    setLoading(false);
  };
  const isUrlValid = function (userInput) {
    if (userInput) {
      var res = userInput.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res == null) return false;
      else return true;
    }
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async () => {
    console.log("data--", value);
    setLoading(true);
    let data = {
      ...value,
      media_type: "url",
      video_type: videotype,
      url: value.url,
      media_file: null,
    };
    if (data) {
      if (!data.name) {
        setError({ ...error, name: "Please enter a title" });
        return;
      }
      if (data.url) {
        if (!isUrlValid(data.url)) {
          setError({ ...error, url: "Invalid Url." });
          return;
        }
      } else {
        setError({ ...error, url: "Please enter a URL." });
      }
    }

    if (value2.id) {
      handleUpdate({ ...value2, ...data });
      return;
    }
    // let res = await dispatch(uploadVideolink(data));
    try {
      const response = await eduleyApi.post(`${MEDIA_VIDEO}`, data, config3);
      // dispatch(slice.actions.getMediaVideoSuccess(response.data));
      // return "success";
      afterSubmit();
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      // return "error";
    }
    setLoading(false);
  };

  return (
    <>
      <Stack spacing={3} direction="row" sx={{ mt: 2 }}>
        <LabelStyle>URL Type</LabelStyle>
        <Stack spacing={3} direction="row">
          {value?.video_type ? (
            <LabelStyle>{value.video_type}</LabelStyle>
          ) : (
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="url_type"
              onChange={(e) => setVideoType(e.target.value)}
              value={videotype}
            >
              <FormControlLabel
                value="youtube"
                control={<Radio />}
                label="Youtube"
              />
              <FormControlLabel
                value="vimeo"
                control={<Radio />}
                label="Vimeo"
              />
            </RadioGroup>
          )}
        </Stack>
      </Stack>
      {videotype && (
        <>
          <Stack spacing={3}>
            <InputField
              label="Title"
              value={value.name}
              setValue={(e) => {
                setValue({ ...value, name: e });
              }}
              setErrorText={(e) => {
                setError({ ...error, name: e ? "" : "Please enter a title" });
              }}
              // error={error?.name}
            />
          </Stack>
          <Stack>
            {error.name && <Label color={"error"}>{error.name}</Label>}
          </Stack>

          {/* <PublicPrivate
            edit={!!value2.name}
            value={value}
            setType={(o) => setValue({ ...value, is_private: o })}
          /> */}
          <Stack spacing={3}>
            <InputField
              label="Video URL"
              value={value.url}
              // error={error?.url}
              setValue={(e) => setValue({ ...value, url: e })}
              setErrorText={(e) => {
                setError({ ...error, url: e ? "" : "Please enter a URL" });
              }}
            />
          </Stack>
          <Stack>
            {error.url && <Label color={"error"}>{error.url}</Label>}
          </Stack>
          <LoadingButton
            variant="contained"
            // label="Submit"
            onClick={() => handleSubmit()}
            loading={loading}
          >
            Submit
          </LoadingButton>
        </>
      )}
    </>
  );
};
const MediaType = ({ mediatype, setMediaType, value }) => {
  const [contenttype, setContentType] = useState("");

  return (
    <Stack spacing={3} direction="row" sx={{ mt: 2 }}>
      <LabelStyle>Content Type</LabelStyle>
      <Stack spacing={3} direction="row">
        {value?.media_type ? (
          <LabelStyle>{value?.media_type}</LabelStyle>
        ) : (
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="media_type"
            onChange={(e) => setMediaType(e.target.value)}
            value={mediatype}
          >
            <FormControlLabel
              value="uploaded"
              control={<Radio />}
              label="Uploaded"
            />
            <FormControlLabel value="url" control={<Radio />} label="URL" />
          </RadioGroup>
        )}
      </Stack>
    </Stack>
  );
};

const InputField = ({ label, value, setValue, setErrorText, error = "" }) => {
  return (
    <Stack direction="row" sx={{ mt: 4 }}>
      <LabelStyle sx={{ mt: 2 }}>{label}</LabelStyle>
      <TextField
        fullWidth
        rows={1}
        size="medium"
        placeholder={label}
        value={value}
        error={error ? true : false}
        helperText={error ? error : ""}
        onChange={(e) => {
          setValue(e.target.value);
          setErrorText && setErrorText(e.target.value);
        }}
        sx={{ typography: "body2", mt: 1 }}
      />
    </Stack>
  );
};
