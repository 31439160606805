import { useEffect, useRef, useState } from "react";

// @mui
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Container,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
// routes
// import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// import useIsMountedRef from "../../hooks/useIsMountedRef";
// components
import Iconify from "src/components/Iconify";
// import {
//   FormProvider,
//   RHFTextField,
//   RHFCheckbox,
// } from "../../components/hook-form";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Label from "src/components/Label";
import Page from "src/components/Page";
import useSettings from "src/hooks/useSettings";
import {
  updateDocument,
  updateDocumentlink,
  UpdateImage,
  UpdateImagelink,
  updateVideo,
  updateVideolink,
  uploadDocument,
  uploadDocumentlink,
  uploadImage,
  uploadImagelink,
  uploadVideo,
  uploadVideolink
} from "src/redux/slices/media";
import { useDispatch, useSelector } from "src/redux/store";
import MediaAttachment from "./MediaAttachment";
import DocumentTypeMedia from "./mediaFormType/DocumentTypeMedia";
import ImageTypeMedia from "./mediaFormType/ImageTypeMedia";
import VideoTypeMedia from "./mediaFormType/VideoTypeMedia";

// ----------------------------------------------------------------------
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 220,
  fontSize: 18,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));
export default function MediaForm({ openForm, data }) {
  const { login } = useAuth();
  const { themeStretch } = useSettings();
  const theme = useTheme();
  // const isMountedRef = useIsMountedRef();
  const { media, isLoading: loading } = useSelector((state) => state.media);
  const [value, setValue] = useState({});
  const [mediatype, setMediaType] = useState("");

  // console.log("media--load", loading);

  useEffect(() => {
    // console.log("media--", data);

    setValue(data);
    setMediaType(data.file_type);
  }, [data]);

  const changeMediaType = (e) => {
    setMediaType(e);
  };

  const afterSubmit = () => {
    console.log("submit");
    openForm({ data: {}, open: false });
  };
  const BackButton = () => {
    return (
      <>
        <IconButton
          onClick={() => afterSubmit()}
          variant="outlined"
          color="primary"
          size="medium"
          aria-label="edit"
          // size="medium"
          sx={{
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? theme.palette.primary.lighter
                  : theme.palette.primary.light,
            },
          }}
        >
          <Iconify
            icon={"akar-icons:arrow-left"}
            color={
              theme.palette.mode === "light"
                ? theme.palette.primary.main
                : theme.palette.primary.light
            }
            size={30}
          />
        </IconButton>
        {data.id ? "Edit Media" : "Add Media"}
      </>
    );
  };

  return (
    <Page title="Media Management">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={<BackButton />} links={[{ name: "" }]} />

        <Card sx={{ p: 4, minHeight: 300 }}>
          <Stack spacing={3}></Stack>
          <Stack spacing={3} direction="row" sx={{ mt: 4 }}>
            <LabelStyle>Media Type</LabelStyle>
            <Stack spacing={3} direction="row">
              {value?.file_type ? (
                <LabelStyle>{value?.file_type}</LabelStyle>
              ) : (
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="media_type"
                  onChange={(e) => changeMediaType(e.target.value)}
                  value={mediatype}
                >
                  <FormControlLabel
                    value="image"
                    control={<Radio />}
                    label="Image"
                  />
                  <FormControlLabel
                    value="video"
                    control={<Radio />}
                    label="Video"
                  />
                  <FormControlLabel
                    value="document"
                    control={<Radio />}
                    label="Document"
                  />
                </RadioGroup>
              )}
            </Stack>
          </Stack>

          {mediatype === "video" && (
            <VideoTypeMedia
              loading={loading}
              afterSubmit={() => afterSubmit()}
              value={value}
            />
          )}
          {mediatype === "image" && (
            <ImageTypeMedia
              loading={loading}
              afterSubmit={() => afterSubmit()}
              value={value}
            />
          )}
          {mediatype === "document" && (
            <DocumentTypeMedia
              loading={loading}
              afterSubmit={() => afterSubmit()}
              value={value}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
// const VideoMedia = ({ loading, afterSubmit, value }) => {
//   const [mediatype, setMediaType] = useState("");

//   useEffect(() => {
//     setMediaType(value.media_type);
//   }, [value]);

//   return (
//     <>
//       <MediaType
//         mediatype={mediatype}
//         setMediaType={setMediaType}
//         value={value}
//       />

//       {mediatype === "url" && (
//         <VideoMediaTypeUrl
//           filetype="video"
//           afterSubmit={afterSubmit}
//           value={value}
//         />
//       )}

//       {mediatype === "uploaded" && (
//         <Uploaded
//           filetype="video"
//           loading={loading}
//           afterSubmit={afterSubmit}
//           value={value}
//         />
//       )}
//     </>
//   );
// };

// const DocumentMedia = ({ loading, afterSubmit, value }) => {
//   const [mediatype, setMediaType] = useState("");
//   useEffect(() => {
//     if (value.media_type) {
//       setMediaType(value.media_type);
//     }
//     // setMediaType(value.media_type);
//   }, [value]);
//   return (
//     <>
//       <MediaType
//         mediatype={mediatype}
//         setMediaType={setMediaType}
//         value={value}
//       />

//       {mediatype === "url" && (
//         <MediaURL filetype="document" afterSubmit={afterSubmit} value={value} />
//       )}
//       {mediatype === "uploaded" && (
//         <Uploaded
//           filetype="document"
//           loading={loading}
//           afterSubmit={afterSubmit}
//           value={value}
//         />
//       )}
//     </>
//   );
// };

// const ImageMedia = ({ loading, afterSubmit, value }) => {
//   const [mediatype, setMediaType] = useState("");
//   useEffect(() => {
//     if (value?.media_type) {
//       setMediaType(value.media_type);
//     }
//   }, [value]);
//   return (
//     <>
//       <MediaType
//         mediatype={mediatype}
//         setMediaType={setMediaType}
//         value={value}
//       />
//       {mediatype === "url" && (
//         <MediaURL filetype="image" afterSubmit={afterSubmit} value={value} />
//       )}
//       {mediatype === "uploaded" && (
//         <Uploaded
//           filetype="image"
//           loading={loading}
//           afterSubmit={afterSubmit}
//           value={value}
//         />
//       )}
//     </>
//   );
// };
const InputField = ({ label, value, setValue, setErrorText, error = "" }) => {
  return (
    <Stack direction="row" sx={{ mt: 4 }}>
      <LabelStyle sx={{ mt: 2 }}>{label}</LabelStyle>
      <TextField
        fullWidth
        rows={1}
        size="medium"
        placeholder={label}
        value={value}
        error={error ? true : false}
        helperText={error ? error : ""}
        onChange={(e) => {
          setValue(e.target.value);
          setErrorText && setErrorText(e.target.value);
        }}
        sx={{ typography: "body2", mt: 1 }}
      />
    </Stack>
  );
};
const MediaType = ({ mediatype, setMediaType, value }) => {
  const [contenttype, setContentType] = useState("");

  return (
    <Stack spacing={3} direction="row" sx={{ mt: 2 }}>
      <LabelStyle>Content Type</LabelStyle>
      <Stack spacing={3} direction="row">
        {value?.media_type ? (
          <LabelStyle>{value?.media_type}</LabelStyle>
        ) : (
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="media_type"
            onChange={(e) => setMediaType(e.target.value)}
            value={mediatype}
          >
            <FormControlLabel
              value="uploaded"
              control={<Radio />}
              label="Uploaded"
            />
            <FormControlLabel value="url" control={<Radio />} label="URL" />
          </RadioGroup>
        )}
      </Stack>
    </Stack>
  );
};

const MediaURL = ({ filetype = "", loading, afterSubmit, value: value2 }) => {
  const [value, setValue] = useState({});
  // const { error } = useSelector((state) => state.media);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (value2) {
      setValue(value2);
    }
  }, [value2]);

  const handleUpdate = (data) => {
    let res = "";
    if (data.file_type === "image") {
      res = dispatch(UpdateImagelink(data, data.id));
    }
    if (data.file_type === "document") {
      res = dispatch(updateDocumentlink(data));
    }
    if (data.file_type === "video") {
      res = dispatch(updateVideolink(data));
    }
    if (res === "success") {
      afterSubmit();
      console.log("success");
    }
  };
  const isUrlValid = function (userInput) {
    if (userInput) {
      var res = userInput.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res == null) return false;
      else return true;
    }
  };
  const handleSubmit = async () => {
    // console.log("data--", value);
    let data = { ...value, media_type: "url" };

    if (data) {
      if (!data.name) {
        setError({ ...error, name: "Please enter a title" });
        return;
      }
      if (data.url) {
        if (!isUrlValid(data.url)) {
          setError({ ...error, url: "Invalid Url." });
          return;
        }
      } else {
        setError({ ...error, url: "Please enter a URL." });
      }
    }

    let res = "";
    if (value2.id) {
      handleUpdate({ ...value2, ...data });
      return;
    }

    if (filetype === "image") {
      // console.log("success----", filetype);
      res = await dispatch(uploadImagelink(data));
    }
    if (filetype === "document") {
      console.log("success----", filetype);

      res = await dispatch(uploadDocumentlink(data));
    }
    if (filetype === "video") {
      console.log("success----", filetype);

      res = await dispatch(uploadVideolink(data));
    }
    console.log("success----", res);
    if (res === "success") {
      afterSubmit();
    }
  };
  return (
    <>
      <Stack spacing={3} sx={{ mt: 2 }}>
        <InputField
          label="Title"
          value={value.name}
          setValue={(e) => setValue({ ...value, name: e })}
          setErrorText={(e) => {
            setError({ ...error, name: e ? "" : "Please enter a title" });
          }}
        />
      </Stack>
      <Stack>{error.name && <Label color={"error"}>{error.name}</Label>}</Stack>
      <Stack spacing={3}>
        <InputField
          label="Media URL"
          value={value.url}
          setValue={(e) => setValue({ ...value, url: e })}
          setErrorText={(e) => {
            setError({ ...error, name: e ? "" : "Please enter a URL" });
          }}
        />
      </Stack>
      <Stack>{error.url && <Label color={"error"}>{error.url}</Label>}</Stack>
      <SubmitButton
        label="Submitt"
        onSubmit={() => handleSubmit()}
        loading={loading}
      />
    </>
  );
};

// uploaded

const Uploaded = ({ filetype, loading, afterSubmit, value: value2 }) => {
  const fileInputRef = useRef(null);
  const [value, setValue] = useState({});
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (value2) {
      setValue(value2);
    }
  }, [value2]);

  const handleUpdate = async () => {
    var filedata = new FormData();
    let v = { ...value };
    delete v.url;
    let res = "";

    if (value2.media_file !== value.media_file) {
      for (const x in v) {
        filedata.append(x, v[x]);
      }
      filedata.append("media_type", "uploaded");

      if (value2.file_type === "image") {
        res = await dispatch(UpdateImage(filedata, value2.id));
      }
      if (value2.file_type === "document") {
        res = await dispatch(updateDocument(filedata, value2.id));
      }
      if (value2.file_type === "video") {
        res = await dispatch(updateVideo(filedata, value2.id));
      }
    } else {
      filedata = { ...value };

      if (value2.file_type === "image") {
        res = dispatch(UpdateImagelink(filedata, value2.id));
      }
      if (value2.file_type === "document") {
        res = dispatch(updateDocumentlink(filedata));
      }
      if (value2.file_type === "video") {
        res = dispatch(updateVideolink(filedata));
      }
    }
    // console.log("data--", filedata);
    if (res === "success") {
      afterSubmit();
    }
  };
  const hasExtension = function (exts, fileName) {
    var regx = new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$");
    var res = regx.test(fileName);
    return res;
  };

  const handleSubmit = async () => {
    let data = { ...value };
    console.log("data--", data);

    if (value2.id) {
      handleUpdate();
      return;
    }
    console.log("data--", data);

    if (!value.name) {
      setError({ ...error, name: "Please enter a title" });
      return;
    }
    if (!value.media_file) {
      setError({ ...error, media_file: "Please upload a file" });
      return;
    }

    if (filetype === "image") {
      if (
        !hasExtension(
          ["jpg", "jpeg", "png", "gif", "JPG", "JPEG", "PNG", "GIF", "svg", 'avif'],
          value.media_file.path
        )
      ) {
        setError({ ...error, media_file: "Please upload a valid image file." });
        return;
      }
    }
    if (filetype === "document") {
      if (
        !hasExtension(
          [
            ".doc",
            ".DOC",
            ".docx",
            ".DOCX",
            ".pdf",
            ".PDF",
            ".txt",
            ".TXT",
            ".xls",
            ".XLS",
            ".xlsx",
            ".XSLX",
            ".ppt",
            ".PPT",
            ".pptx",
            ".PPTX",
          ],
          value.media_file.path
        )
      ) {
        setError({ ...error, media_file: "Please upload a valid document." });
        return;
      }
    }
    if (filetype === "video") {
      if (
        !hasExtension(
          [".mp4", ".MP4", ".avi", ".AVI", ".mov", ".MOV", ".wmv", ".WMV"],
          value.media_file.path
        )
      ) {
        setError({ ...error, media_file: "Please upload a valid vedio file." });
        return;
      }
    }
    var filedata = new FormData();
    for (const x in value) {
      filedata.append(x, value[x]);
    }

    filedata.append("media_type", "uploaded");
    let res = "";
    if (filetype === "image") {
      res = await dispatch(uploadImage(filedata));
    }
    if (filetype === "document") {
      res = await dispatch(uploadDocument(filedata));
    }
    if (filetype === "video") {
      res = await dispatch(uploadVideo(filedata));
    }
    console.log("data--", filedata);
    if (res === "success") {
      afterSubmit();
    }
  };

  const handleAttach = () => {
    fileInputRef.current?.click();
  };
  const setfiles = (e) => {
    console.log("e", e);
    if (e != undefined) {
      setValue({ ...value, media_file: e });
    } else {
      setValue({ ...value, media_file: value2.media_file });
    }
  };
  return (
    <>
      <Stack spacing={3}>
        <InputField
          label="Title"
          value={value.name || value2.name}
          // error={error.name}
          setValue={(e) => setValue({ ...value, name: e })}
          setErrorText={(e) => {
            setError({ ...error, name: e ? "" : "Please enter a Title" });
          }}
        />
      </Stack>
      <Stack>{error.name && <Label color={"error"}>{error.name}</Label>}</Stack>
      <Stack spacing={3} direction="row" sx={{ mt: 2, mb: 2 }}>
        <LabelStyle sx={{ mt: 2 }}>Choose Media</LabelStyle>

        <MediaAttachment
          filetype={filetype}
          attachments={value2.media_file}
          setfiles={(p) => setfiles(p)}
        />
      </Stack>
      <Stack>
        {error.media_file && (
          <Label color={"warning"}>{error.media_file}</Label>
        )}
      </Stack>
      <SubmitButton
        label="Upload"
        onSubmit={() => handleSubmit()}
        loading={loading}
      />
    </>
  );
};

// const VideoMediaTypeUrl = ({ loading, afterSubmit, value: value2 }) => {
//   const [videotype, setVideoType] = useState("");
//   const [value, setValue] = useState({});

//   console.log("value2", value2);
//   const dispatch = useDispatch();
//   const { video } = useSelector((state) => state.media);
//   const[error,setError]=useState({});
//   console.log("video", video);

//   useEffect(() => {
//     if (value2.id) {
//       dispatch(getVideo(value2.id));
//     }
//     if (value2.id) {
//       setVideoType(value2.video_type);
//     }
//   }, [value2]);

//   useEffect(() => {
//     if (value2.id) {
//       setValue(video);
//       setVideoType(video.video_type);
//     }
//   }, [video]);

//   const handleUpdate = async (data) => {
//     let res = await dispatch(updateVideolink(data, data.id));
//     if (res === "success") {
//       afterSubmit();
//     }
//   };
//   const isUrlValid=function(userInput) {
//     if(userInput)
//     {
//     var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
//     if(res == null)
//         return false;
//     else
//         return true;
//     }
//    }
//    const { enqueueSnackbar } = useSnackbar();
//   const handleSubmit = async () => {
//     console.log("data--", value);
//     let data = {
//       ...value,
//       media_type: "url",
//       video_type: videotype,
//       url: value.url,
//     };
//   if(data)
//   {
//     if(!data.name)
//     {
//       setError({ ...error, name: "Please enter a title" });
//       return;
//     }
//     if(data.url)
//     {
//     if(!isUrlValid(data.url))
//     {
//      setError({ ...error, url: "Invalid Url." });
//      return;
//     }

//   }
//   else
//   {
//     setError({ ...error, url: "Please enter a URL." });

//   }
//   }

//     if (value2.id) {
//       handleUpdate({ ...value2, ...data });
//       return;
//     }
//     let res = await dispatch(uploadVideolink(data));
//     if (res === "success") {
//       afterSubmit();
//     }
//   };
//   return (
//     <>
//       <Stack spacing={3} direction="row" sx={{ mt: 2 }}>
//         <LabelStyle>URL Type</LabelStyle>
//         <Stack spacing={3} direction="row">
//           {value?.video_type ? (
//             <LabelStyle>{value.video_type}</LabelStyle>
//           ) : (
//             <RadioGroup
//               row
//               aria-labelledby="demo-row-radio-buttons-group-label"
//               name="url_type"
//               onChange={(e) => setVideoType(e.target.value)}
//               value={videotype}
//             >
//               <FormControlLabel
//                 value="youtube"
//                 control={<Radio />}
//                 label="Youtube"
//               />
//               <FormControlLabel
//                 value="vimeo"
//                 control={<Radio />}
//                 label="Vimeo"
//               />
//             </RadioGroup>
//           )}
//         </Stack>
//       </Stack>
//       {videotype && (
//         <>
//           <Stack spacing={3}>
//             <InputField
//               label="Title"
//               value={value.name}
//               setValue={(e) =>{setValue({ ...value, name: e })}}
//               setErrorText={(e) =>{setError({ ...error, name:e?"":"Please enter a title" })}}
//               // error={error?.name}
//             />
//           </Stack>
//           <Stack>
//         {error.name && (
//           <Label color={"error"}>{error.name}</Label>
//         )}
//         </Stack>
//           <Stack spacing={3}>
//             <InputField
//               label="Video URL"
//               value={value.url}
//               // error={error?.url}
//               setValue={(e) => setValue({ ...value, url: e })}
//               setErrorText={(e) =>{setError({ ...error, url:e?"":"Please enter a URL" })}}
//             />
//           </Stack>
//           <Stack>
//         {error.url && (
//           <Label color={"error"}>{error.url}</Label>
//         )}
//         </Stack>
//           <SubmitButton
//             label="Submit"
//             onSubmit={() => handleSubmit()}
//             loading={loading}
//           />
//         </>
//       )}
//     </>
//   );
// };

const SubmitButton = ({ label, onSubmit, loading }) => {
  console.log("submit loading", loading);
  const { media, isLoading } = useSelector((state) => state.media);

  return (
    <LoadingButton
      sx={{ pl: 4, pr: 4 }}
      size="large"
      type="submit"
      variant="contained"
      loading={isLoading}
      onClick={() => onSubmit()}
    >
      {label}
    </LoadingButton>
  );
};
