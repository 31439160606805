import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import { eduleyApi } from 'src/api/apiConfig';
import {
  COURSE_CATEGORY,
  COURSE_CONTENT_ORDER,
  COURSE_DOC_ASSIGNMENT,
  COURSE_LANGUAGE,
  COURSE_LESSON,
  COURSE_MODULE,
  COURSE_QUIZ,
  COURSE_SECTION_ORDER,
  CREATE_COURSE,
  CURRENCY_LIST,
  INSTITUTE_LIST
} from 'src/apiUrl';
// utils
import _, { isArray } from 'lodash';
// import axios from "axios";
//
import snackbar from 'src/utils/snackbar';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  loadingtoform: false,
  error: null,
  section: 0,
  categorylist: [],
  languagelist: [],
  currencylist: [],
  instructorlist: [],
  instituteList: {results:[]},
  addcourse: { firstform: {}, secondform: {}, thirdform: {} },
  module: [],
  board: {
    cards: {},
    columns: {},
    columnOrder: []
  },
  configuration: {
    customweightage: {}
  }
};

const slice = createSlice({
  name: 'courseupdate',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    loadingToSave(state) {
      state.loadingtoform = true;
    },
    loadtoclose(state) {
      state.loadingtoform = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setEmptyState(state) {
      state.isLoading = false;
      state.error = null;
      state.addcourse = { firstform: {}, secondform: {}, thirdform: {} };
      state.module = [];
      state.board = {
        cards: {},
        columns: {},
        columnOrder: []
      };
      state.section = 0;
    },
    // set section1
    setfirstform(state, action) {
      state.addcourse.firstform = action.payload;
      state.loadingtoform = false;
      state.section = 1;
    },

    //set student data
    setUserData(state, action) {
      state.addcourse.firstform = action.payload;
      state.loadingtoform = false;
    },

    updateCourseData(state, action) {
      state.addcourse.firstform = action.payload;
      state.loadingtoform = false;
    },
    fetchfirstform(state, action) {
      state.addcourse.firstform = action.payload;
      state.loadingtoform = false;
      // state.section = 1;
    },
    changesection(state, action) {
      state.section = action.payload;
      state.loadingtoform = false;
    },
    setinstructor(state, action) {
      state.instructorlist = action.payload;
    },
    setInstituteList(state, action) {
      state.instituteList = {...action.payload,results:[...state?.instituteList?.results,...action.payload.results]};
    },
    setInstituteListLoading(state, action) {
      state.instituteList = {...state.instituteList,...action.payload}
    },
    updateLesson(state, action) {
      const lesson = action.payload;
      if (!_.isEmpty(lesson?.id)) {
        state.board.cards[lesson?.id] = lesson;
      }
    },

    setcategory(state, action) {
      state.categorylist = action.payload;
    },
    setlanguagelist(state, action) {
      state.languagelist = action.payload;
    },
    setcurrency(state, action) {
      state.currencylist = action.payload;
    },
    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      state.board = board;
    },
    setModule(state, action) {
      state.module = action.payload;
    },

    // CREATE NEW COLUMN
    createModuleSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn
      };
      state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;
      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
    changeconfiguration(state, action) {
      state.configuration = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
export const { changeconfiguration, updateCourseData } = slice.actions;

// ----------------------------------------------------------------------
// export const c_update = { changeset };
const accessToken = localStorage.getItem('accessToken');

const config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  }
};

export function getCourse(p) {
  return async () => {
    dispatch(actions.loadingToSave());
    try {
      const response = await eduleyApi.get(`${CREATE_COURSE}${p}/`);
      
      // If payment_type is recurring, set payment_option to full_payment
      if (response.data.payment_type === 'recurring') {
        response.data.payment_plans = ['full_payment'];
      }
      
      dispatch(slice.actions.fetchfirstform(response.data));
      let section = [...response.data.section];
      let board = creatingBoardData(section);
      dispatch(slice.actions.getBoardSuccess(board));
      dispatch(slice.actions.setModule(section));
      dispatch(slice.actions.loadtoclose());
    } catch (error) {}
  };
}
export function setEmptyState() {
  return async () => {
    dispatch(slice.actions.setEmptyState());
  };
}
export function createcourse(p) {
  return async () => {
    dispatch(actions.loadingToSave());
    try {
      const response = await eduleyApi.post(CREATE_COURSE, p);
      dispatch(slice.actions.setfirstform(response.data));
      let section = [...response.data.section];
      let board = creatingBoardData(section);
      console.log('board-------', board);
      dispatch(slice.actions.getBoardSuccess(board));
    } catch (error) {
      console.log('error,-');
    }
    dispatch(slice.actions.loadtoclose());
  };
}

const creatingBoardData = (data) => {
  let cards = {};
  let columns = {};
  let columnOrder = [];
  let p1 = [...data].map((item) => {
    let id = [];
    let t = [...item.content].map((c) => {
      cards[c.id] = c;
      id.push(c.id);
    });
    columns[item.id] = { ...item, cardIds: [...id] };
    columnOrder.push(item.id);
  });
  return {
    cards: cards,
    columns: columns,
    columnOrder: columnOrder
  };
};

export function editcourse(p) {
  return async () => {
    dispatch(actions.loadingToSave());
    try {
      const response = await eduleyApi.patch(`${CREATE_COURSE}${p.id}/`, p);
      dispatch(slice.actions.setfirstform(response.data));
      let section = [...response.data.section];
      let board = creatingBoardData(section);
      // console.log("board-------", board);
      dispatch(slice.actions.getBoardSuccess(board));
    } catch (error) {
      // console.log("error,-", error?.data?.data);
      snackbar.error(error?.data?.data?.non_field_errors);
    }
    dispatch(slice.actions.loadtoclose());
  };
}
export function courseUpdating(p) {
  return async () => {
    dispatch(actions.loadingToSave());
    try {
      const response = await eduleyApi.patch(`${CREATE_COURSE}${p.id}/`, p);
      dispatch(slice.actions.updateCourseData(response.data));

      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.loadtoclose());
      console.log('Couse Payment Plan:', error.response);
      if (!_.isEmpty(error.data.data.non_field_errors)) {
        if (isArray(error.data.data.non_field_errors)) {
          snackbar.error(error.data.data.non_field_errors[0]);
        } else {
          snackbar.error(error.data.data.non_field_errors);
        }
      }
      return Promise.reject(error);
    }
    // dispatch(slice.actions.loadtoclose());
  };
}

export const contentOrdering = (p) => async (dispatch) => {
  console.log('contern order', p);
  try {
    const response = await eduleyApi.post(`${COURSE_CONTENT_ORDER}`, p);
  } catch (error) {}
  dispatch(slice.actions.loadtoclose());
};
export function getcategory() {
  return async () => {
    try {
      const response = await eduleyApi.get(COURSE_CATEGORY);
      dispatch(slice.actions.setcategory(response.data));
    } catch (error) {}
    // console.log("category----", response.data);
  };
}

export function getcurrency() {
  return async () => {
    try {
      const response = await eduleyApi.get(CURRENCY_LIST);
      // console.log('currency----', response.data);
      dispatch(slice.actions.setcurrency(response.data));
    } catch (error) {}
    dispatch(slice.actions.loadtoclose());
  };
}
export function getInstructorList() {
  return async () => {
    const response = await eduleyApi.get(CURRENCY_LIST, config);
    console.log('currency----', response.data);
    dispatch(slice.actions.setinstructor(response.data));
  };
}

export function getInstituteList(query) {
  return async () => {
    dispatch(slice.actions.setInstituteListLoading({isLoading:true}))
    const response = await eduleyApi.get(`${INSTITUTE_LIST}${query}`, config);
    dispatch(slice.actions.setInstituteList({...response.data,isLoading:false}));
  };
}

export function getlanguagelist() {
  return async () => {
    const response = await eduleyApi.get(COURSE_LANGUAGE, config);
    dispatch(slice.actions.setlanguagelist(response.data));
  };
}

export function changesection(p) {
  return () => {
    console.log('changesection-', p);
    dispatch(slice.actions.changesection(p));
  };
}

export function updatecoverImage(p) {
  return async () => {
    dispatch(actions.loadingToSave());
    try {
      const response = await eduleyApi.patch(`${CREATE_COURSE}${p.id}/`, p);
      dispatch(getCourse(p.id));
      dispatch(slice.actions.changesection(4));
      dispatch(slice.actions.loadtoclose());

      return 'success';
    } catch (error) {
      dispatch(slice.actions.loadtoclose());
      return error;
    }
  };
}

// export function updatepublish(p) {
//   return async () => {
//     // dispatch(actions.loadingToSave());
//     try {
//       const response = await axios.patch(`${CREATE_COURSE}${p.id}/`, p, config);
//       console.log("publish----", response.data);
//       return "success";
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

export function getBoard() {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(
      slice.actions.getBoardSuccess({
        cards: [],
        columns: [],
        columnOrder: []
      })
    );
    dispatch(slice.actions.loadtoclose());
  };
}

// ----------------------------------------------------------------------

export function createModule(newColumn) {
  // let course_id = initialState.addcourse;
  // console.log("course_id", course_id);
  return async () => {
    dispatch(slice.actions.startLoading());
    let data = {
      name: newColumn.name,
      course: newColumn.id,
      // days_to_complete: 2,
      discription: '',
      index: newColumn.index
    };
    // console.log("data to post--", data);
    try {
      const response = await eduleyApi.post(COURSE_MODULE, data);
      // console.log("response-----", response);
      dispatch(
        slice.actions.createModuleSuccess({
          ...response.data,
          cardIds: []
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.loadtoclose());

    dispatch(getCourse(newColumn.id));
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.put(`${COURSE_MODULE}${updateColumn.id}/`, updateColumn);
      let data = response.data;
      dispatch(slice.actions.updateColumnSuccess(updateColumn));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.loadtoclose());
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await eduleyApi.delete(`${COURSE_MODULE}${columnId}/`);
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.loadtoclose());
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder, data) {
  console.log('newColumnOrder pcolumn', newColumnOrder);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.post(`${COURSE_SECTION_ORDER}`, data);
      console.log('response section order--', response);
      // getCourse(data.course);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(getCourse(data.id));
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns, data = {}) {
  console.log('columnsOrder pc', data, columns);
  return async () => {
    dispatch(slice.actions.persistCard(columns));
    const response = await eduleyApi.post(`${COURSE_CONTENT_ORDER}`, data);
    console.log('response content order--', response);
  };
}
export function persistCard2(columns, data = {}) {
  console.log('columnsOrder', columns);
  return async () => {
    dispatch(slice.actions.persistCard(columns));
    const response = await eduleyApi.post(`${COURSE_CONTENT_ORDER}`, data);
    console.log('response content order--', response);
    dispatch(getCourse(data.id));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId, module }) {
  // console.log("card", card);
  let data = { name: card.name, module: module };
  return async () => {
    dispatch(slice.actions.addTask({ card: data, columnId: module }));
  };
}

export function updateLesson(lesson) {
  return async () => {
    dispatch(slice.actions.updateLesson(lesson));
  };
}

// ----------------------------------------------------------------------

export const deleteTask =
  ({ cardId, columnId }, card) =>
  async (dispatch) => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
    if (card.content_type === 'quiz') {
      try {
        const res = await eduleyApi.delete(`${COURSE_QUIZ}${card.id}/`);
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
    if (card.content_type === 'lesson') {
      try {
        const res = await eduleyApi.delete(`${COURSE_LESSON}${card.id}/`);
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
    if (card.content_type === 'document-assignment' || card.content_type === 'assignment' || card.content_type === 'homework') {
      try {
        const res = await eduleyApi.delete(`${COURSE_DOC_ASSIGNMENT}${card.id}/`);
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };

/**s
 * Update the student document.
 *
 * @param {Object} data - The data to update the document with.
 * @returns {Function} - The asynchronous function that updates the document.
 */
export function updateUserDocument(data) {
  return async () => {
    dispatch(actions.loadingToSave()); // Dispatch the action to show loading state.
    try {
      const response = await eduleyApi.patch(`${CREATE_COURSE}${data.id}/`, data); // Send a PATCH request to update the document.
      dispatch(slice.actions.setUserData(response.data)); // Dispatch the action to update the student data with the response data.
    } catch (error) {
      snackbar.error(error?.data?.data?.non_field_errors); // Display an error message if the update fails.
    }

    dispatch(slice.actions.loadtoclose()); // Dispatch the action to close the loading state.
  };
}
