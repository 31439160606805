import { useEffect, useRef, useState } from 'react';
// @mui
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { contentOrdering } from 'src/redux/slices/courseupdate';
import { addDocAssignment, getDocAssignment, setDocAssignment, updateDocAssignment } from 'src/redux/slices/lesson';
import { useDispatch } from 'src/redux/store';
import MediaModal from 'src/sections/media/MediaModal';
import ClosewithoutSave from '../component/ClosewithoutSave';
import ContentWeightage from '../component/ContentWeightage';

import { useSnackbar } from 'notistack';
import { TimeFieldHHMMSS } from 'src/components/TimeField';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import AssignmentView from 'src/sections/@dashboard/course/courseView/contentView/AssignmentView';
import TextEditorForDescription from '../component/TextEditorForDescription';
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 180,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const defaultData = {
  can_skip: false,
  can_proceed_without_marking: false,
  start_datetime: null,
  submission_type: 'uploaded',
  content_type: 'assignment'
};

export default function DocAssignment({ data, handleUpdate, section, index, courseid, accessibleRoles = [] }) {
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const { data: docdata, isLoading, error } = useSelector((state) => state.lesson.docAssignment);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { openwithoutsave } = useSelector((state) => state.lesson);
  const [value, setValue] = useState(defaultData);
  const [errors, setErrors] = useState({});
  const [openMedia, setOpenMedia] = useState(false);
  const { addcourse } = useSelector((state) => state.courseupdate);
  useEffect(() => {
    console.log('Data ID:', data);
    if (data.id) {
      dispatch(getDocAssignment(data.id));
    }
    return () => {
      dispatch(setDocAssignment({}));
    };
  }, [data.id]);

  useEffect(() => {
    setErrors({ ...error });
  }, [error]);

  useEffect(() => {
    if (docdata?.id) {
      // Check if `docdata` and `docdata.content` exist before accessing `docdata.content.id`
      setValue((prevState) => ({
        ...prevState,
        ...docdata,
        content: docdata.content ? docdata.content.id : null
      }));
    }
  }, [docdata]);

  // console.log("lecturedata--error--", error);

  const saveLesson = async () => {
    // console.log(value);
   
    if (value?.total_marks <= 0 || !value?.total_marks) {
      enqueueSnackbar('Please add total marks', {
        variant: 'error'
      });
      return;
    }
    setLoading(true);
    let v = {
      ...value,
      section: section.id,
      transcript_weightage: !_.isEmpty(value?.transcript_weightage) ? value?.transcript_weightage.id : null,
      index: index,
      start_datetime: value?.start_datetime ? value?.start_datetime : null,
      questions: value?.questions?.map((a) => ({
        ...a,
        attachment: a.attachment?.id || null
      }))
    };
    let v2 = {
      ...value,
      transcript_weightage: !_.isEmpty(value?.transcript_weightage) ? value?.transcript_weightage.id : null,
      start_datetime: value?.start_datetime ? value?.start_datetime : null,
      questions: value?.questions?.map((a) => ({
        ...a,
        attachment: a.attachment?.id || null
      }))
    };
    // console.log('doc assignment--', v)
    let res = '';
    if (!value?.id) {
      res = await dispatch(addDocAssignment(v, section));
      // console.log(res)
      if (res.res == 'success') {
        let l = {
          id: courseid,
          section: [{ id: section.id, content: [...section?.content, res.data] }]
        };
        let r = await dispatch(contentOrdering(l));
      }
      // console.log("addLecture---", v);
    } else {
      res = await dispatch(updateDocAssignment(v2, data.id));
    }
    // console.log("res---", res);
    if (res === 'success' || res.res == 'success') {
      handleUpdate({ ...data, ...value });
    }
    setLoading(false);
  };
  const addingMedia = (media) => {
    setValue({ ...value, content: media?.id || null });
    setOpenMedia(false);
  };
  const handleChange = (e) => {
    // console.log("object", e.target.value);
    if (e.target.name == 'submission_type' && e.target.value == 'subjective') {
      // for setup total marks again
      setValue({
        ...value,
        [e.target.name]: e.target.value,
        total_marks: value?.questions?.map((a) => a.maximum_marks).reduce((a, b) => +a + +b, 0)
      });
    } else {
      setValue({ ...value, [e.target.name]: e.target.value });
    }
  };
  const handleWeightage = (weightage) => {
    setValue({ ...value, transcript_weightage: weightage });
  };
  const handleUpdateQuestion = (question) => {
    setValue({
      ...value,
      questions: question,
      total_marks: question?.map((a) => a.maximum_marks).reduce((a, b) => +a + +b, 0)
    });
  };
  const handleChangeDescription = (e) => {
    if (!!data.id) {
      if (!!value?.description) {
        setValue((prevState) => ({
          ...prevState,
          description: e
        }));
      }
    } else {
      setValue((prevState) => ({
        ...prevState,
        description: e
      }));
    }
  };
  console.log('object -- assignment', value);
  if (isLoading && !loading) {
    return (
      <>
        {new Array(6).fill(0).map((a, i) => (
          <Skeleton variant="rectangular" height={65} key={i} sx={{ my: 1 }} />
        ))}
      </>
    );
  }

  return (
    <RoleBasedGuard accessibleRoles={accessibleRoles} noPermissionChildren={<>{value && <AssignmentView data={value} />}</>}>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Assignment name</LabelStyle>
        <TextField
          fullWidth
          //   multiline
          error={errors.name ? true : false}
          helperText={errors.name?.join(', ') || 'Name of the assignment'}
          rows={1}
          size="small"
          placeholder="Assignment name"
          value={value?.name}
          name="name"
          onChange={handleChange}
          sx={{ typography: 'body2', mt: 1 }}
        />
      </Stack>

      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Description</LabelStyle>
        <div style={{ width: '100%' }}>
          <TextEditorForDescription
            value={value?.description}
            setValue={(e) => handleChangeDescription(e)}
            errors={errors.description ? errors.description : ''}
            helperText={errors.description}
          />
        </div>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Assignment file</LabelStyle>
        <Stack direction="column">
          <MediaModal media={value?.content} addingMedia={(p) => addingMedia(p)} />
          <Typography variant="body2" sx={{ mt: 1, fontSize: 12, ml: 1 }} color={errors.assignment ? 'error.main' : 'text.secondary'}>
            {errors.document ? errors.assignment.join('') : 'Attach the file for the assignment'}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Weightage Group in Grade</LabelStyle>
        <ContentWeightage data={value?.transcript_weightage} handleSubmit={handleWeightage} type="assignment"  disabled={!addcourse?.firstform?.is_transcript} />
      </Stack>
      <Stack direction="row" sx={{ mt: 2 }}>
        <LabelStyle >Number of Hours </LabelStyle>
        <TimeFieldHHMMSS
          value={value?.time_allotment}
          error={errors.time_allotment}
          helperText={errors.time_allotment||'Total Hours Spent (Displayed in Transcript)'}
          handleValue={(e) => {
            setValue({ ...value, time_allotment: e });
          }}
        />
      </Stack>
      <Stack direction="row" sx={{ mt: 2 }}> 
        <LabelStyle >Start Date</LabelStyle>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            value={value?.start_datetime}
            onChange={(e) => setValue({ ...value, start_datetime: e })}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors?.start_datetime || false}
                helperText={
                  errors?.start_datetime?.join(',') || (
                    <>
                      Select Date from when you want this content to be available for the students.
                      <br />
                      This can be left blank if you want this to be always available.
                    </>
                  )
                }
                fullWidth
                variant="outlined"
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Total Marks</LabelStyle>
        <TextField
          fullWidth
          disabled={value?.submission_type === 'subjective'}
          //   multiline
          error={errors.total_marks ? true : false}
          helperText={errors.total_marks?.join(', ') || 'Enter Total Marks'}
          rows={1}
          size="small"
          placeholder="Enter Total Marks"
          value={value?.total_marks}
          name="total_marks"
          onChange={handleChange}
          sx={{ typography: 'body2', mt: 1 }}
        />
      </Stack>
      <SaveButton saving={() => saveLesson()} loading={loading} />
      {openwithoutsave && (
        <ClosewithoutSave
          oncancel={() => handleUpdate()}
          onok={() => {
            saveLesson();
          }}
        />
      )}
    </RoleBasedGuard>
  );
}
const SaveButton = ({ saving, loading }) => (
  <Stack direction="row">
    <LoadingButton loadingPosition="start" loading={loading} variant="contained" onClick={() => saving()} sx={{ ml: 2, mt: 2 }}>
      Save Content
    </LoadingButton>
  </Stack>
);
