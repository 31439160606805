import {
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import React from "react";
import Iconify from "src/components/Iconify";
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 220,
  fontSize: 18,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const PublicPrivate = ({ setType, value, edit }) => {
  const toolTipby = () => (
    <HtmlTooltip
      title={
        <React.Fragment>
          <em>
            <u> Public Media</u> → Public media are media files which are
            accessible to all. Media files for program description, landing
            pages, etc.. should be marked public
          </em>
          <br />
          <em>
            <u> Restricted Media</u> → Restricted content are media files which
            is not accessible to unauthorised audience. Program Curriculum
            content should be marked as restricted.
          </em>
        </React.Fragment>
      }
    >
      <IconButton>
        <Iconify icon="ant-design:exclamation-circle-twotone" />
      </IconButton>
    </HtmlTooltip>
  );
  return (
    <Stack spacing={3} direction="row" sx={{ mt: 2 }}>
      <LabelStyle sx={{ mt: 1 }} noWrap>
        Media Permission {toolTipby()}{" "}
      </LabelStyle>
      <Stack spacing={3} direction="row">
        <ToggleButtonGroup
          color={!value?.is_private ? "primary" : "info"}
          value={!value?.is_private ? "public" : "private"}
          exclusive
          onChange={(e, v) => {
            if (v == "public") setType(false);
            if (v == "private") setType(true);
          }}
        >
          <ToggleButton value="public">Public</ToggleButton>
          <ToggleButton value="private">Restricted</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </Stack>
  );
};

export default PublicPrivate;
