import { useEffect, useState } from "react";
// @mui
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// routes
// hooks
// _mock_
// import { _userList } from "../../_mock";
// components
// import Page from "src/components/Page";
import Label from "src/components/Label";
// import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
// import SearchNotFound from "src/components/SearchNotFound";
// import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
// sections
import { Link as RouterLink } from "react-router-dom";
import { eduleyApi } from "src/api/apiConfig";
import { IAP_USER } from "src/apiUrl";
import Iconify from "src/components/Iconify";
import { getInstructor } from "src/redux/slices/user";
import { dispatch, useDispatch } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import {
  UserListHead,
  UserListToolbar,
} from "src/sections/@dashboard/instructor";
// import { getInstructorList } from "src/redux/slices/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "", alignRight: false },
  { id: "id", label: "Id", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "", label: "Status", alignRight: false },
  { id: "designation", label: "Qualification", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

const ReviewerlistModal = ({
  allreviewer,
  handlereviewer,
  instructor: ins_id,
}) => {
  const [openModal, setOpenModal] = useState(false);
  // const [instructor, setInstructor] = useState({});

  console.log(allreviewer);

  const setinstructorview = (p) => {
    handlereviewer(p);
    setOpenModal(false);
    console.log("save---", p);
  };

  return (
    <>
      <TextField
        onClick={() => setOpenModal(true)}
        value={`${allreviewer?.length} Reviewers`}
        fullWidth
      />
      <InsModal
        ins_id={ins_id}
        openModal={openModal}
        data={allreviewer}
        setinstructor={(p) => setinstructorview(p)}
        setclose={() => setOpenModal(false)}
      />
    </>
  );
};

const InsModal = ({ openModal, setinstructor, setclose, data, ins_id }) => {
  //   const [openModal, setOpenModal] = React.useState(false);
  const [instructor, setInstructor] = useState({});
  useEffect(() => {
    getins();
  }, [ins_id]);

  const getins = async () => {
    let res = await dispatch(getInstructor(ins_id));
    setInstructor(res);
  };
  return (
    <Dialog
      open={openModal}
      onClose={() => setclose(false)}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      fullWidth
      maxWidth="lg"
    // height="auto"
    >
      <InstructorTable
        instructor={instructor}
        handleCancel={() => setclose(false)}
        reviewer={data}
        handlesavereviewer={setinstructor}
      />
    </Dialog>
  );
};

export default ReviewerlistModal;

function InstructorTable({
  handlesavereviewer,
  reviewer,
  handleCancel,
  instructor,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [users, setUsers] = useState({});

  const [openEdit, setOpenEdit] = useState({ data: {}, open: false });
  const [data, setdata] = useState(reviewer);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  console.log("inst-----", users);
  let query =
    "?groups__name=Reviewer&limit=" +
    rowsPerPage +
    "&offset=" +
    page * rowsPerPage +
    "&ordering=" +
    (order == "asc" ? "-" : "") +
    orderBy +
    "&search=" +
    filterName;

  useEffect(() => {
    getInstructorList();
  }, [dispatch, page, rowsPerPage, order, orderBy, filterName, openEdit]);

  const getInstructorList = async () => {
    try {
      let res = await eduleyApi.get(`${IAP_USER}${query}`);
      setUserList(res.data.results);
      setUsers(res.data);
      // console.log('res==', res.data)
    } catch (error) {
      // console.log(error)
    }
  };

  const handleRequestSort = (property, p) => {
    const isAsc = orderBy === p && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(p);
  };

  const handleSelectAllClick = (checked) => { };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    console.log("filterName", filterName);
    setFilterName(filterName);
    setPage(0);
  };

  // const handleDeleteUser = (userId) => {
  //     const deleteUser = userList.filter((user) => user.id !== userId);
  //     setSelected([]);
  //     setUserList(deleteUser);
  //     dispatch(deleteuser(userId));
  //     setOpenDelete({ data: "", open: false });
  // };
  const changeinstructor = (item) => {
    let value = [...data];
    if (checkavailabereviewer(item)) {
      value = data.filter((a) => a.id != item.id);
    } else {
      let rajeev = {
        ...item,
        first_name: item.first_name,
        last_name: item.last_name,
        id: item.id,
      };
      value = [...data, rajeev];
    }
    setdata(value);
  };
  const handledeletereviewer = (e, item) => {
    let r = [...data].filter((a) => a.id != item.id);
    setdata(r);
  };
  const handleDeleteMultiUser = (selected) => { };

  // const emptyRows =
  //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;
  const checkavailabereviewer = (item) => {
    // console.log(item)
    let checked = false;
    data.forEach((element) => {
      if (!checked) {
        checked = element?.id == item?.id;
      }
    });
    return checked;
  };
  return (
    <>
      <Card sx={{ pb: 2 }}>
        <CardHeader title="Reviewers data" />
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, p: 2 }}>
          {data?.map((item) => (
            <Chip
              key={item.id}
              label={item.first_name + " " + item.last_name}
              clickable
              deleteIcon={<Iconify icon="ant-design:close-circle-twotone" />}
              onDelete={(e) => handledeletereviewer(e, item)}
            // onClick={() => console.log("clicked chip")}
            />
          ))}
        </Box>
        {instructor?.id && (<Stack direction={'row'}>
          <Typography variant="body2" sx={{ color: "text.secondary", ml: 2 }}>
            The instructor of the program,
          </Typography>

          <Typography variant="body2" sx={{ color: 'primary.main', ml: 1 }}>
            {' ' + instructor.iap_user?.first_name +
              " " +
              instructor.iap_user?.last_name || "" + ' '}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary", ml: 1 }}>
            is also a reviewer by default.
          </Typography>
        </Stack>
        )}
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          onDeleteUsers={() => handleDeleteMultiUser(selected)}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList?.length}
                numSelected={selected?.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {userList?.map((row, index) => {
                  const {
                    id = "",
                    institute = 1,
                    email = "",
                    password = "",
                    first_name = "co-instructor",
                    last_name = "Test",
                    profile_photo = null,
                    groups = [5],
                    is_active = true,
                    date_joined = "2022-09-09T09:20:21.273140",
                    designation = "",
                  } = row;
                  const isItemSelected = selected.indexOf(first_name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                    // tabIndex={-1}
                    // role="checkbox"
                    // selected={isItemSelected}
                    // aria-checked={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={() => changeinstructor(row)}
                      >
                        <Checkbox checked={checkavailabereviewer(row)} />
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        onClick={() => changeinstructor(row)}
                      >
                        {id}
                      </TableCell>
                      <TableCell
                        sx={{ display: "flex", alignItems: "center" }}
                        onClick={() => changeinstructor(row)}
                      >
                        <Avatar
                          alt={first_name}
                          src={profile_photo}
                          sx={{ mr: 2 }}
                        />
                        <Typography variant="subtitle2" noWrap>
                          {first_name + " " + last_name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => changeinstructor(row)}
                      >
                        <Label
                          variant={
                            theme.palette.mode === "light" ? "ghost" : "filled"
                          }
                          color={!is_active ? "error" : "success"}
                        >
                          {is_active ? "Active" : "Inactive"}
                        </Label>
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => changeinstructor(row)}
                      >
                        {designation || "-"}
                      </TableCell>
                      <TableCell align="left">
                        {/* {description?.length > 45 ? description?.slice(0, 45) + '...' : description || "-"} */}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {users?.count == 0 && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={3}
                      sx={{ py: 3, color: "text.main" }}
                    >
                      No Reviewer Data Found
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      sx={{ py: 3, color: "text.main" }}
                    >
                      <Button
                        sx={{ width: 120 }}
                        variant="contained"
                        size="small"
                        component={RouterLink}
                        to={PATH_DASHBOARD.user.newUser}
                      >
                        New User
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {users.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        <Stack direction={"row"} justifyContent="center">
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            onClick={() => handlesavereviewer(data)}
          >
            Save
          </Button>
          <Button variant="outlined" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </Stack>
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------
