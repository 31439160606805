// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, ''),
    course: path(ROOTS_DASHBOARD, '/program'),
    cohort: path(ROOTS_DASHBOARD, '/cohort'),
    addcourse: path(ROOTS_DASHBOARD, '/add-program'),
    editcourse: path(ROOTS_DASHBOARD, '/edit-program/:id'),
    viewCourse: path(ROOTS_DASHBOARD, '/view-program/:id'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    student: path(ROOTS_DASHBOARD, '/student-profile'),
    forums: path(ROOTS_DASHBOARD, '/discussion-board')
    // banking: path(ROOTS_DASHBOARD, '/banking'),
    // booking: path(ROOTS_DASHBOARD, '/booking')
  },
  management: {
    root: path(ROOTS_DASHBOARD, '/management'),
    course_management: path(ROOTS_DASHBOARD, '/program-management'),
    cohort_management: path(ROOTS_DASHBOARD, '/cohort-management')
  },
  subscriptions: {
    root: path(ROOTS_DASHBOARD, '/student-management'),
    general: path(ROOTS_DASHBOARD, '/student-progress'),
    paymentPlan: path(ROOTS_DASHBOARD, '/paymentplan')
  },
  track: {
    root: path(ROOTS_DASHBOARD, '/track'),
    quiz: path(ROOTS_DASHBOARD, '/grades/quiz'),
    quizbyid: path(ROOTS_DASHBOARD, '/grades/quiz/:id'),
    assignment: path(ROOTS_DASHBOARD, '/grades/assignment'),
    homework: path(ROOTS_DASHBOARD, '/grades/homework')
  },
  forums: {
    root: path(ROOTS_DASHBOARD, '/discussion-board')
  },
  media: {
    root: path(ROOTS_DASHBOARD, '/media-management')
    // all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  fund: {
    root: path(ROOTS_DASHBOARD, '/platform-management'),
    statement: path(ROOTS_DASHBOARD, '/platform-management/statement'),
    payout: path(ROOTS_DASHBOARD, '/platform-management/payout'),
    payout_eduley: path(ROOTS_DASHBOARD, '/platform-management/eduley'),
    platform_settings: {
      list: path(ROOTS_DASHBOARD, '/platform-management/platform-settings'),
      edit: (id) => path(ROOTS_DASHBOARD, `/platform-management/platform-settings/edit/${id}`)
    }
  },
  configuration: {
    root: path(ROOTS_DASHBOARD, '/configuration'),
    theme: path(ROOTS_DASHBOARD, '/configuration/theme'),
    paymentmethod: path(ROOTS_DASHBOARD, '/configuration/paymentmethod'),
    gamification: path(ROOTS_DASHBOARD, '/configuration/gamification'),
    support_emails: path(ROOTS_DASHBOARD, '/configuration/support-teams'),
    score_weightage: path(ROOTS_DASHBOARD, '/configuration/score_weightage'),
    studentFinance: path(ROOTS_DASHBOARD, '/configuration/student-finance'),
    enrollmentDocuments: path(ROOTS_DASHBOARD, '/configuration/program-enrollment-documents'),
    profileEnrichment: path(ROOTS_DASHBOARD, '/configuration/student-profile-enrichment')
    // institute: path(ROOTS_DASHBOARD, "/theme/institute"),
    // landing: path(ROOTS_DASHBOARD, "/theme/landingpage"),
  },
  analytics: {
    root: path(ROOTS_DASHBOARD, '/analytics'),
    subscriptions: path(ROOTS_DASHBOARD, '/analytics/program-performance'),
    subscriptionsById: path(ROOTS_DASHBOARD, '/analytics/subscriptions/:id'),
    revenue: path(ROOTS_DASHBOARD, '/analytics/revenue'),
    revenueById: path(ROOTS_DASHBOARD, '/analytics/revenue/:id'),
    progress: path(ROOTS_DASHBOARD, '/analytics/progress'),
    progressById: path(ROOTS_DASHBOARD, '/analytics/progress/:id'),
    gamification: path(ROOTS_DASHBOARD, '/analytics/gamification'),
    course: path(ROOTS_DASHBOARD, '/analytics/program'),
    cohort: {
      list: path(ROOTS_DASHBOARD, '/analytics/attendance'),
      report: (course, cohort) => path(ROOTS_DASHBOARD, `/analytics/attendance/course/${course}/cohort/${cohort}`),
      studentReport: (course, cohort, student) => path(ROOTS_DASHBOARD, `/analytics/attendance/course/${course}/cohort/${cohort}/student/${student}`)
    },
    instructor: {
      list: path(ROOTS_DASHBOARD, '/analytics/instructor')
      // report: (id) => path(ROOTS_DASHBOARD, `/analytics/cohort/${id}`),
    }
  },
  connect: {
    root: path(ROOTS_DASHBOARD, '/connect'),
    list: path(ROOTS_DASHBOARD, '/connect'),
    create: path(ROOTS_DASHBOARD, '/connect/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/connect/edit/${id}`)
  },
  // calendar: path(ROOTS_DASHBOARD, '/calendar'),
  // kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/staff-management'),
    profile: path(ROOTS_DASHBOARD, '/staff-management/profile'),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/staff-management'),
    newUser: path(ROOTS_DASHBOARD, '/staff-management/new'),
    editById: path(ROOTS_DASHBOARD, `/staff-management/:id/edit`),
    account: path(ROOTS_DASHBOARD, '/staff-management/list')
    // account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  instructor: {
    root: path(ROOTS_DASHBOARD, '/instructor'),
    list: path(ROOTS_DASHBOARD, '/instructor/list')
  },
  support: {
    root: path(ROOTS_DASHBOARD, '/support'),
    eduley_support: path(ROOTS_DASHBOARD, '/support/eduley-support'),
    student_support: path(ROOTS_DASHBOARD, '/support/student-support')
    // productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    // list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    // newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    // editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    // checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    // invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    // root: path(ROOTS_DASHBOARD, '/blog'),
    // posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    // post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    // postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    // newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  cohortAttendance: {
    list: path(ROOTS_DASHBOARD, '/attendance'),
    edit: (cohortId, date, eventId) => path(ROOTS_DASHBOARD, `/attendance/${cohortId}/${date}/${eventId}`)
  }
};

export const PATH_DOCS = '/introduction';
