import PropTypes from 'prop-types';
// @mui
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
// utils
// components

// ----------------------------------------------------------------------

PlatformManagementRow.propTypes = {
  row: PropTypes.object.isRequired
};

export default function PlatformManagementRow({ row, onEdit }) {
  const { name, whitelisted_domains, blacklisted_emails, whitelisted_emails, pending_permissions } = row;
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={false}>
        <TableCell align="left">
          <Typography variant="overline" sx={{ textTransform: 'capitalize' }}>
            {name}
          </Typography>
        </TableCell>

        <TableCell align="left">
          {whitelisted_domains.length > 0 ? (
            <Stack>
              <Typography>{whitelisted_domains[0].domain}</Typography>
              {whitelisted_domains.length > 1 && (
                <Typography sx={{ fontSize: 12 }} color="red">
                  +{whitelisted_domains.length - 1}more
                </Typography>
              )}
            </Stack>
          ) : (
            '-'
          )}
        </TableCell>

        

        <TableCell align="left">
          {whitelisted_emails.length > 0 ? (
            <Stack>
              <Typography>{whitelisted_emails[0].email}</Typography>
              {whitelisted_emails.length > 1 && (
                <Typography sx={{ fontSize: 12 }} color="red">
                  +{whitelisted_emails.length - 1}more
                </Typography>
              )}
            </Stack>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="left">
          {blacklisted_emails.length > 0 ? (
            <Stack>
              <Typography>{blacklisted_emails[0].email}</Typography>
              {blacklisted_emails.length > 1 && (
                <Typography sx={{ fontSize: 12 }} color="red">
                  +{blacklisted_emails.length - 1}more
                </Typography>
              )}
            </Stack>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="left">{pending_permissions}</TableCell>
        <TableCell align="left">
          <Typography
            onClick={() => {
              handleClosePopover();
              onEdit();
            }}
            sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            <Iconify icon="basil:edit-outline" sx={{ marginRight: 0.5 }} /> Edit
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
