import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { MEDIA_LIST } from 'src/apiUrl';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';
import ImageMedia from 'src/sections/media/ImageMedia';
import MediaForm from 'src/sections/media/MediaForm';
import MediaViewer from 'src/sections/media/mediaViewer/MediaViewer';
import VideoMedia from 'src/sections/media/VideoMedia';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 180,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));
const Image1 = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white
}));

export default function MediaModal2({ addingMedia, removingMedia, media = '', file_type, public_private = '', viewOnly = false }) {
  const [openModal, setOpenModal] = useState(false);
  const [mediaData, setMediaData] = useState({});
  const [media_type, setMediatype] = useState(file_type);

  const [flag, setFlag] = useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };
  console.log('media', file_type, openModal, media, flag, !media?.id, media == '', media == null);
  console.log('media', media);
  const getMedia = async () => {
    try {
      const response = await axios.get(`${MEDIA_LIST}${media}/`, config);
      console.log('response', response);
      setMediaData(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (media) {
      getMedia();
    }
  }, [media]);
  useEffect(() => {
    if (!media || media == '' || media == null) {
      setFlag(true);
    }
  }, []);
  const handleClose = () => {
    setOpenModal(false);
  };
  const addingMedia2 = (p) => {
    setOpenModal(false);
    addingMedia(p);
    setMediaData(p);
    setFlag(false);
  };
  const removingMedia2 = (p) => {
    setOpenModal(false);
    removingMedia(p);
    setMediaData(p);
    setFlag(true);
  };
  const MediaView = () => (
    <>
      <Stack direction={'column'}>
        <MediaViewer
          file={mediaData}
          action={
            <>
              { (
                <Image
                  key={media.id}
                  src={media.media_file || media.url}
                  // onClick={() => setOpenModal(true)}
                  sx={{
                    width: 104,
                    height: 104,
                    borderRadius: '8px',
                    cursor: 'pointer'
                  }}
                />
              )}

              {/* {mediaData.file_type == "video" && (<Iconify icon='lets-icons:video' />)}
    {mediaData.file_type == "document" && (<Iconify icon='ic:twotone-perm-media' />)} */}
              <Typography
                variant="subtitle2"
                sx={{
                  // color: "info.main",

                  '&:hover': {
                    cursor: 'pointer',
                    color: 'primary.main'
                  }
                }}
              >
                {mediaData.name} {'   '}
              </Typography>
            </>
          }
        />
        {/* <Typography sx={{ color: 'text.secondary', fontSize: 10 }}>Media Type- {` ${capitalCase(mediaData?.file_type || '')} `}</Typography> */}
      </Stack>
    </>
  );

  if (viewOnly) {
    return (
      <>
        <MediaView />
      </>
    );
  }

  return (
    <>
      {flag ? (
        <Stack direction={'row'} spacing={3}>
          <Button variant="outlined" startIcon={<Iconify icon="fluent-mdl2:media-add" />} onClick={() => setOpenModal(true)}>
            Choose Media
          </Button>
        </Stack>
      ) : media ? (
        <>
          <MediaView />
          <Stack sx={{ mt: 2 }} direction={'row'} spacing={3}>
            <Button startIcon={<Iconify icon="fluent-mdl2:media-add" />} variant="contained" onClick={() => setOpenModal(true)}>
              Change Media
            </Button>
            <Button onClick={() => removingMedia2({})} variant="outlined" startIcon={<Iconify icon="ep:delete-filled" />}>
              Remove
            </Button>
          </Stack>
        </>
      ) : (
        <Stack direction={'row'} spacing={3}>
          <Button variant="contained" onClick={() => setOpenModal(true)}>
            Choose Media
          </Button>
          {/* <Button
            onClick={() => removingMedia2({})}
            variant="outlined"
            startIcon={<Iconify icon="ep:delete-filled" />}
          >
            Remove Field
          </Button> */}
        </Stack>
      )}

      {file_type == 'image' && (
        <MediaModallist
          openModal={openModal}
          handleClose={handleClose}
          addingMedia={(p) => addingMedia2(p)}
          file_type={file_type}
          public_private={public_private}
        />
      )}
      {file_type == 'video' && (
        <VideoMediaModallist
          openModal={openModal}
          handleClose={handleClose}
          addingMedia={(p) => addingMedia2(p)}
          file_type={file_type}
          public_private={public_private}
        />
      )}
    </>
  );
}

const MediaModallist = ({ openModal, handleClose, addingMedia, public_private = '' }) => {
  const [view, setView] = useState('list');
  const [media, setMedia] = React.useState({ data: {}, open: false });
  const selectedMedia = (p) => {
    console.log('media', p);
    addingMedia(p);
  };
  const openForm = (data) => {
    setMedia({ data: data, open: true });
  };
  return (
    <>
      <Dialog open={openModal} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle sx={{ mb: 1 }}>
          {public_private == 'public' && (
            <Typography variant="body2">
              <b>PLEASE NOTE:</b>{' '}
              {!media.open ? (
                <>
                  Only Media with <b>“Public”</b> Permission is being shown because <b>“Restricted”</b> Media cannot used on common pages.
                </>
              ) : (
                <>
                  Only Media with <b>“Public”</b> Permission should be uploaded because <b>“Restricted”</b> Media cannot used on common pages.
                </>
              )}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {media.open ? (
            <MediaForm openForm={(p) => setMedia(p)} data={media.data} />
          ) : (
            <ImageMedia selectedMedia={(p) => selectedMedia(p)} addmedia={(p) => openForm(p)} public_private={public_private} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const VideoMediaModallist = ({ openModal, handleClose, addingMedia, public_private }) => {
  const [media, setMedia] = React.useState({ data: {}, open: false });

  const selectedMedia = (p) => {
    console.log('media', p);
    addingMedia(p);
  };
  const openForm = (data) => {
    setMedia({ data: data, open: true });
  };
  return (
    <>
      <Dialog open={openModal} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle sx={{ mb: 1 }}>
          {public_private == 'public' && (
            <Typography variant="body2">
              <b>PLEASE NOTE:</b>{' '}
              {!media.open ? (
                <>
                  Only Media with <b>“Public”</b> Permission is being shown because <b>“Restricted”</b> Media cannot used on common pages.
                </>
              ) : (
                <>
                  Only Media with <b>“Public”</b> Permission should be uploaded because <b>“Restricted”</b> Media cannot used on common pages.
                </>
              )}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {media.open ? (
            <MediaForm openForm={(p) => setMedia(p)} data={media.data} />
          ) : (
            <VideoMedia selectedMedia={(p) => selectedMedia(p)} addmedia={(p) => openForm(p)} public_private={public_private} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
