import { useEffect, useState } from 'react';
// @mui
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Checkbox,
  // Box,
  // IconButton,
  Stack,
  TextField,
  // Divider,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { eduleyApi } from 'src/api/apiConfig';
import { COURSE_LESSON } from 'src/apiUrl';
import { TimeFieldHHMMSS } from 'src/components/TimeField';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { contentOrdering } from 'src/redux/slices/courseupdate';
import { useDispatch, useSelector } from 'src/redux/store';
import MediaModal from 'src/sections/media/MediaModal';
import LessonView from '../../course/courseView/contentView/LessonView';
import ClosewithoutSave from './component/ClosewithoutSave';
import LessonResource from './component/LessonResource';
import TextEditorForDescription from './component/TextEditorForDescription';
import UserNextTask from './component/UserNextTask';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 180,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

export default function Lesson({ data = {}, handleUpdate, section, index, courseid, handleCancel, accessibleRoles = [] }) {
  const { id } = data;
  const dispatch = useDispatch();
  // const [lecturedata, setLecturedata] = useState({});
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { openwithoutsave } = useSelector((state) => state.lesson);

  const [value, setValue] = useState({
    can_skip: true,
    start_datetime: null,
    additional_steps: [{ name: 'Share your thoughts about the course in the Discussion Board.', index: 1 }]
  });
  const [errors, setErrors] = useState({});
  const [openMedia, setOpenMedia] = useState(false);
  useEffect(() => {
    if (data?.id) {
      // console.log("kdk---", data);
      getLecture(data.id);
    }
  }, [data.id]);

  const getLecture = async (id) => {
    // console.log("getLecture", id);
    setLoading(true);
    // dispatch(actions.startLoadingLecture());
    try {
      const res = await eduleyApi.get(`${COURSE_LESSON}${id}/`);
      // dispatch(actions.hasError(null));
      // setLecturedata(res.data);
      setValue({ ...res.data, content: res?.data?.content?.id || null });
      console.log('getLecture', res.data);
      // dispatch(actions.setLecture(res.data));
    } catch (error) {
      console.log(error);
      setErrors(error);
      // dispatch(actions.hasError(error));
    }
    setLoading(false);
  };
  // useEffect(() => {
  //   setErrors({ ...error });
  // }, [error]);

  // useEffect(() => {
  //   if (data.id && value?.name !== lecturedata?.name) {
  //     setValue({ ...lecturedata, content: lecturedata?.content?.id || null });
  //   }
  // }, [lecturedata]);

  const saveLesson = async () => {
    let v = {
      ...value,
      section: section.id,
      index: index,
      start_datetime: value.start_datetime ? value.start_datetime : null,
      resources: value?.resources?.map((a) => ({
        ...a,
        resource: a.resource.id
      }))
    };
    let v2 = {
      ...value,
      start_datetime: value.start_datetime ? value.start_datetime : null,
      resources: value?.resources?.map((a) => ({
        ...a,
        resource: a.resource.id
      }))
    };
    let res = '';
    if (!data.id) {
      res = await addLecture(v, section);
    } else {
      res = await updateLecture(v2, id);
    }

    // if (res === "success" || res.res === "success") {
    // }
  };

  const addingMedia = (media) => {
    setValue({ ...value, content: media?.id || null });
    setOpenMedia(false);
  };
  const addLecture = async (data, section) => {
    setLoading(true);
    try {
      const res = await eduleyApi.post(`${COURSE_LESSON}`, data);
      let l = {
        id: courseid,
        section: [{ id: section.id, content: [...section?.content, res.data] }]
      };
      let r = dispatch(contentOrdering(l));
      handleUpdate({ ...data, ...value });

      return { res: 'success', data: res.data };
    } catch (error) {
      console.log('er--', error);
      enqueueSnackbar('Error in saving lesson', { variant: 'error' });
      setErrors(error.data.data);
    } finally {
      setLoading(false);
    }
  };
  const updateLecture = async (data, id) => {
    setLoading(true);

    // dispatch(actions.startLoadingLecture());
    try {
      const res = await eduleyApi.patch(`${COURSE_LESSON}${id}/`, data);
      // dispatch(actions.hasError(null));
      // dispatch(actions.setLecture(res.data));
      handleUpdate({ ...data, ...value });
    } catch (error) {
      // console.log("er--", error);
      enqueueSnackbar('Error in saving lesson', { variant: 'error' });
      setErrors(error.data.data);

      // dispatch(actions.hasError(error.response.data));
      // return "error";
    } finally {
      setLoading(false);
    }
  };

  const handlechange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value
    });
  };
  const handleChangeDescription = (e) => {
    setValue({ ...value, description: e });
  };
  return (
    <RoleBasedGuard
      accessibleRoles={accessibleRoles}
      noPermissionChildren={
        <>
          <LessonView data={value} />
        </>
      }
    >
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Lesson Name</LabelStyle>
        <TextField
          fullWidth
          error={errors.name ? true : false}
          helperText={errors.name?.join(',') || 'Name of the lesson'}
          rows={1}
          size="small"
          placeholder="Lesson Name"
          name="name"
          onChange={handlechange}
          value={value.name}
          sx={{ typography: 'body2', mt: 1 }}
        />
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Attachment</LabelStyle>
        <Stack direction="column">
          <MediaModal media={value.content} addingMedia={(p) => addingMedia(p)} />
          <Typography variant="body2" sx={{ mt: 1, fontSize: 12, ml: 1 }} color={errors.content ? 'error.main' : 'text.secondary'}>
            {errors.content ? errors.content : ' Content to be displayed in the lesson'}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Description</LabelStyle>
        <div style={{ width: '100%' }}>
          <TextEditorForDescription
            value={value.description}
            setValue={handleChangeDescription}
            errors={errors.description ? errors.description : ''}
            helperText={errors.description}
          />
        </div>
      </Stack>

      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Resource</LabelStyle>
        <LessonResource
          data={value?.resources || []}
          handleSave={(p) => {
            setValue({ ...value, resources: p });
          }}
        />
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Next Steps</LabelStyle>
        <UserNextTask
          data={value?.additional_steps}
          handleSave={(p) => {
            setValue({ ...value, additional_steps: p });
          }}
        />
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Time Allotment </LabelStyle>
        <TimeFieldHHMMSS
          value={value.time_allotment}
          error={errors.time_allotment}
          helperText={errors.time_allotment}
          handleValue={(e) => {
            setValue({ ...value, time_allotment: e });
          }}
        />
      </Stack>
      <Stack direction="row" sx={{ mt: 2 }}>
        <LabelStyle >Start Date</LabelStyle>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            value={value.start_datetime}
            onChange={(e) => setValue({ ...value, start_datetime: e })}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors?.start_datetime || false}
                helperText={
                  errors?.start_datetime?.join(',') || (
                    <>
                      Select Date from when you want this content to be available for the students.
                      <br />
                      This can be left blank if you want this to be always available.
                    </>
                  )
                }
                fullWidth
                variant="outlined"
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2 }}>Can Skip</LabelStyle>
        <Checkbox
          // defaultChecked
          checked={value.can_skip}
          onChange={(e) => setValue({ ...value, can_skip: e.target.checked })}
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{ mt: 1 }}
        />
        <Typography variant="body2" sx={{ mt: 2, fontSize: 12 }} color="text.secondary">
          Can student skip this content and proceed to next content?
        </Typography>
      </Stack>
      <Stack direction="row" alignItems={'center'}>
        <LoadingButton loading={loading} variant="contained" sx={{ ml: 2, mt: 2 }} onClick={() => saveLesson()}>
          Save Content
        </LoadingButton>
      </Stack>
      {openwithoutsave && (
        <ClosewithoutSave
          oncancel={handleCancel}
          onok={() => {
            saveLesson();
          }}
        />
      )}
    </RoleBasedGuard>
  );
}
