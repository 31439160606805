import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { DialogAnimate, IconButtonAnimate } from '../../../components/animate';

//
import { Assignments, Homework, Lecture, Quiz } from './addlesson';

import { sentenceCase } from 'change-case';
import useSettings from 'src/hooks/useSettings';
import { closewithoutsave, openwithoutsave } from 'src/redux/slices/lesson';
import { useDispatch } from 'src/redux/store';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

ModuleLessonDetails.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func
};

export default function ModuleLessonDetails({ card, isOpen, onClose, onDeleteTask, saveLesson, index = 1, section = {}, courseid }) {
  const isDesktop = useResponsive('up', 'sm');
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [lessonType, setLessonType] = useState(card.content_type || '');
  const [openModal, setOpenModal] = useState(false);
  const { name, content_type: type = '' } = card;
  const { themeStretch } = useSettings();
  const [name2, setName2] = useState(name);
  const [data, setData] = useState({});

  const onDeleteTask2 = () => {
    setOpenModal(true);
  };
  const handleDeleteTask = () => {
    onDeleteTask(card);
    setOpenModal(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleUpdate = (p) => {
    console.log('handle update--', p);
    dispatch(closewithoutsave());
    saveLesson(p);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleUpdate({ ...card, name: name2 });
    }
  };
  const handleChangeName = (event) => {
    setName2(event.target.value);
  };
  const handleChangePrioritize = (event) => {
    setLessonType(event.target.value);
  };
  const closeDrawer = () => {
    dispatch(openwithoutsave());
  };
  return (
    <>
      <Drawer open={isOpen} onClose={closeDrawer} anchor="right" PaperProps={{ sx: { width: { xs: 1, sm: 800 } } }}>
        <Stack p={2.5} direction="row" alignItems="center">
          {!isDesktop && (
            <>
              <Tooltip title="Back">
                <IconButtonAnimate onClick={onClose} sx={{ mr: 1 }}>
                  <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
            </>
          )}

          <Stack direction="row" spacing={1} justifyContent="space-between" flexGrow={1}>
            <Tooltip title="Go back">
              <IconButtonAnimate onClick={onClose} size="small">
                <Iconify icon={'material-symbols:arrow-back-rounded'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>

            <>
              {/* <Tooltip title="Attachment">
                <IconButtonAnimate size="small" onClick={handleAttach}>
                  <Iconify icon={'eva:attach-2-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
              <input ref={fileInputRef} type="file" style={{ display: 'none' }} /> */}
            </>
            <Typography variant="h6"> {sentenceCase(card?.content_type || type)}</Typography>
            <Stack direction={'row'}>
              <Tooltip title={`Delete ${sentenceCase(type)}`}>
                <IconButtonAnimate onClick={onDeleteTask2} size="small">
                  <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>

              {/* <Tooltip title="More actions">
              <IconButtonAnimate size="small">
              <Iconify
              icon={"eva:more-horizontal-fill"}
              width={20}
              height={20}
              />
              </IconButtonAnimate>
            </Tooltip> */}
              <Tooltip title={`Close ${sentenceCase(card?.content_type || type)}`}>
                <IconButtonAnimate onClick={onClose} size="small">
                  <Iconify icon={'codicon:close'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>

        <Divider />
        <Scrollbar>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            {!type && (
              <Stack spacing={3} sx={{ px: 2.5, py: 3 }}>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="lessonType" onChange={handleChangePrioritize}>
                  <FormControlLabel value="lesson" control={<Radio />} label="Lesson" />
                  <FormControlLabel value="quiz" control={<Radio />} label="Quiz" />
                  <FormControlLabel value="assignment" control={<Radio />} label="Assignment" />
                  {/* <FormControlLabel value="homework" control={<Radio />} label="Homework" /> */}
                </RadioGroup>
              </Stack>
            )}
            {'lesson' === lessonType && (
              <Lecture
                courseid={courseid}
                data={card}
                section={section}
                handleUpdate={handleUpdate}
                index={index}
                handleCancel={onClose}
                accessibleRoles={[1, 2, 3]}
              />
            )}
            {'quiz' === lessonType && (
              <Quiz
                courseid={courseid}
                data={card}
                section={section}
                index={index}
                handleUpdate={handleUpdate}
                handleCancel={onClose}
                accessibleRoles={[1, 2, 3]}
              />
            )}
            {'assignment' === lessonType && (
              <Assignments
                data={card}
                courseid={courseid}
                index={index}
                section={section}
                handleUpdate={handleUpdate}
                handleCancel={onClose}
                accessibleRoles={[1, 2, 3]}
              />
            )}
            {'homework' === lessonType && (
              <Homework
                data={card}
                courseid={courseid}
                index={index}
                section={section}
                handleUpdate={handleUpdate}
                handleCancel={onClose}
                accessibleRoles={[1, 2, 3]}
              />
            )}
          </Container>
        </Scrollbar>
        <Divider />
      </Drawer>
      <DialogAnimate open={openModal} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h6">Delete {sentenceCase(type)}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this {sentenceCase(type)} ? This is an irreversible action.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />

          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loadingIndicator="Loading..." onClick={() => handleDeleteTask(card)}>
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogAnimate>
    </>
  );
}
