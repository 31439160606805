// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from 'src/components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  support: getIcon('ic_support'),
  setting: getIcon('ic_setting')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Administration',
    id: 'Administration',
    permission: [1, 2, 3, 4, 6, 9],
    items: [
      {
        id: '#1.1',
        title: 'User Profile',
        path: PATH_DASHBOARD.general.student,
        permission: [1, 2, 6, 4, 9],
        icon: <Iconify icon={'ph:student-light'} />
      },
      {
        id: '#1.2',
        title: 'Payment Plan',
        path: PATH_DASHBOARD.subscriptions.paymentPlan,
        icon: <Iconify icon="wpf:renew-subscription" />,
        permission: [1, 2, 4, 6, 9]
      }
      //   {
    ]
  },
  {
    subheader: 'EDUCATION',
    id: 'education',
    permission: [1, 2, 3, 4, 5, 8, 6, 9],
    items: [
      // {
      //   id: '#2',
      //   title: 'Program',
      //   path: PATH_DASHBOARD.general.root,
      //   icon: <Iconify icon="carbon:course" />,
      //   permission: [1, 2, 3, 5, 6, 9],
      //   children: [
      //     {
      //       id: '#2.1',
      //       title: 'Programs',
      //       path: PATH_DASHBOARD.general.course,
      //       permission: [1, 2, 3, 5, 6, 9]
      //     }
      //     // {
      //     //   id: '#2.2',
      //     //   title: 'Cohorts',
      //     //   path: PATH_DASHBOARD.general.cohort,
      //     //   permission: [1, 2, 3, 5, 6, 9]
      //     // }
      //   ]
      // },
      {
        id: '#cohort-attendance',
        title: 'Attendance',
        path: PATH_DASHBOARD.cohortAttendance.list,
        icon: <Iconify icon="solar:notebook-line-duotone" />,
        permission: [1, 2, 3, 4, 5]
      },
      {
        id: '#2',
        title: 'Grades',
        path: PATH_DASHBOARD.track.root,
        icon: <Iconify icon="arcticons:score-sheets" />,
        permission: [1, 2, 3, 5],
        children: [
          {
            id: '#3.1',
            title: 'Quiz Track',
            path: PATH_DASHBOARD.track.quiz,
            permission: [1, 2, 3, 5]
          },
          {
            id: '#3.2',
            title: 'Assignment Track',
            path: PATH_DASHBOARD.track.assignment,
            permission: [1, 2, 3, 5]
          }
          // {
          //   id: '#3.3',
          //   title: 'Homework',
          //   path: PATH_DASHBOARD.track.homework,
          //   permission: [1, 2, 3, 5]
          // }
        ]
      },
      {
        id: '#4',
        title: 'Discussion Board',
        path: PATH_DASHBOARD.general.forums,
        permission: [1, 2, 3, 9, 5],
        icon: <Iconify icon={'mdi-light:forum'} />
      },
      {
        id: '#6',
        title: 'Connect',
        path: PATH_DASHBOARD.connect.list,
        icon: <Iconify icon="solar:clapperboard-play-bold-duotone" />,
        permission: [1, 2, 3, 5]
      },
      {
        id: '#8.1',
        title: 'User Progress',
        path: PATH_DASHBOARD.subscriptions.general,
        permission: [1, 2, 3, 5, 8, 9],
        icon: <Iconify icon="wpf:renew-subscription" />
      }
    ]
  },
  //   },
  // APP
  // roles
  // ----------------------------------------------------------------------
  //  2, name: 'Institute Admin',
  //  4, name: 'Finance',
  //  3, name: 'Instructor',
  //  5, name: 'co-instructor',
  //  6, name: 'Counsellor',
  // 7, name: 'Ambassador',
  // 8, name: 'Education Admin',
  // 9, name: 'Education Officer',
  {
    subheader: 'management',
    id: 'management',
    permission: [1, 2, 3, 5, 6, 4, 9, 10],
    items: [
      {
        id: '#1',
        title: 'Staff Management',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
        permission: [1, 2]
      },
      {
        id: '#2.1',
        title: 'Program Management',
        path: PATH_DASHBOARD.management.course_management,
        permission: [1, 2, 3, 9, 5, 6],
        icon: <Iconify icon="carbon:course" />
      },
      {
        id: '#2.2',
        title: 'Cohort Management',
        path: PATH_DASHBOARD.management.cohort_management,
        permission: [1, 2, 4, 3, 9],
        icon: <Iconify icon="streamline:quality-education" />
      },
      {
        id: '#3',
        title: 'Media Management ',
        path: PATH_DASHBOARD.media.root,
        permission: [1, 2, 3, 4],
        icon: <Iconify icon={'ic:baseline-perm-media'} />
      },
      {
        id: '#2',
        title: 'analytics',
        path: PATH_DASHBOARD.analytics.root,
        icon: ICONS.analytics,
        permission: [1, 2, 4, 3],
        children: [
          {
            id: '#2.1',
            title: 'Program Performance',
            path: PATH_DASHBOARD.analytics.subscriptions,
            permission: [1, 2, 4]
          },
          {
            id: '#2.2',
            title: 'Revenue Report',
            path: PATH_DASHBOARD.analytics.revenue,
            permission: [1, 2, 4]
          },
          {
            id: '#2.4',
            title: 'Instructor Performance',
            path: PATH_DASHBOARD.analytics.instructor.list,
            // permission: [1, 2, 6, 3]
            permission: [1, 2]
          },
          // {
          //   id: '#2.4',
          //   title: 'Attendance',
          //   path: PATH_DASHBOARD.analytics.cohort.list,
          //   permission: [1, 2, 6, 3, 4]
          // },
          {
            id: '#2.3',
            title: 'Gamification',
            path: PATH_DASHBOARD.analytics.gamification,
            permission: [1, 2]
          }
        ]
      },

      {
        id: '#5',
        title: 'Configuration',
        path: PATH_DASHBOARD.configuration.root,
        icon: ICONS.setting,
        permission: [1, 2],
        children: [
          {
            id: '#5.1',
            title: 'Theme',
            path: PATH_DASHBOARD.configuration.theme,
            permission: [1, 2]
          },
          {
            id: '#5.2',
            title: 'Payment Method',
            path: PATH_DASHBOARD.configuration.paymentmethod,
            permission: [1, 2]
          },
          {
            id: '#5.3',
            title: 'Gamification',
            path: PATH_DASHBOARD.configuration.gamification,
            permission: [1, 2]
          },
          {
            id: '#5.4',
            title: 'Custom Scores',
            path: PATH_DASHBOARD.configuration.score_weightage,
            permission: [1, 2]
          },
          {
            id: '#5.5',
            title: 'Support Team',
            path: PATH_DASHBOARD.configuration.support_emails,
            permission: [1, 2]
          },
          // {
          //   id: '#5.6',
          //   title: 'User Financial Assistance',
          //   path: PATH_DASHBOARD.configuration.studentFinance,
          //   permission: [1, 2]
          // },
          // {
          //   id: '#5.7',
          //   title: 'Program Enrollment Documents',
          //   path: PATH_DASHBOARD.configuration.enrollmentDocuments,
          //   permission: [1, 2]
          // },
          {
            id: '#5.7',
            title: 'User Profile Enrichment',
            path: PATH_DASHBOARD.configuration.profileEnrichment,
            permission: [1, 2]
          }
        ]
      },
      {
        id: '#4',
        title: 'Platform Management',
        path: PATH_DASHBOARD.fund.root,
        icon: ICONS.banking,
        permission: [1, 2, 4],
        children: [
          // {
          //   id: '#4.1',
          //   title: 'Statement',
          //   path: PATH_DASHBOARD.fund.statement,
          //   permission: [1, 2, 4]
          // },
          // {
          //   id: '#4.2',
          //   title: 'Payouts',
          //   path: PATH_DASHBOARD.fund.payout,
          //   permission: [1, 2, 4]
          // },
          // {
          //   id: '#4.3',
          //   title: 'Pay to Budgetnista',
          //   path: PATH_DASHBOARD.fund.payout_eduley,
          //   permission: [1, 2, 4]
          // },
          {
            id: '#4.4',
            title: 'Platform Settings',
            path: PATH_DASHBOARD.fund.platform_settings.list,
            permission: [1, 2, 4]
          }
        ]
      }
    ]
  },

  {
    subheader: 'connect to admin',
    id: 'help',
    permission: [1, 2, 3],
    items: [
      {
        id: '#1',
        title: 'Support',
        path: PATH_DASHBOARD.support.root,
        icon: <Iconify icon={'ic:outline-support-agent'} />,
        permission: [1, 2, 3],
        children: [
          {
            id: '#1.1',
            title: 'Budgetnista Support',
            path: PATH_DASHBOARD.support.eduley_support,
            permission: [1, 2, 3]
          },
          {
            id: '#1.2',
            title: 'User Support',
            path: PATH_DASHBOARD.support.student_support,
            permission: [1, 2, 3]
          }
        ]
      }
    ]
  }
];
export default navConfig;
