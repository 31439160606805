import PropTypes from 'prop-types';
// @mui
import { Box, Button, Stack } from '@mui/material';
// import DocViewer from "react-doc-viewer";
import Iconify from './Iconify';
// ----------------------------------------------------------------------

DocumentViewer.propTypes = {
  disabledEffect: PropTypes.bool,
  effect: PropTypes.string,
  ratio: PropTypes.oneOf(['4/3', '3/4', '6/4', '4/6', '16/9', '9/16', '21/9', '9/21', '1/1']),
  sx: PropTypes.object
};

export default function DocumentViewer({ ratio, name, disabledEffect = false, effect = 'blur', sx, src, ...other }) {
  const handleDownload = () => {
    const url = src;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}-${new Date()}`);

    // link.setAttribute('download',);
    document.body.appendChild(link);
    link.click();
  };

  if (ratio) {
    return (
      <>
        <Stack direction={'column'} alignItems="center">
          <Box
            component="span"
            sx={{
              width: 1,
              lineHeight: 0,
              display: 'block',
              overflow: 'hidden',
              position: 'relative',
              pt: getRatio(ratio),
              cursor: 'pointer',
              '& .wrapper': {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                lineHeight: 0,
                position: 'absolute',
                backgroundSize: 'cover !important'
              },
              ...sx
            }}
          >
            <Iconify
              sx={{ width: 1, height: 1, objectFit: 'cover' }}
              {...other}
              onClick={() => handleDownload(src)}
              icon={'material-symbols:assignment-outline'}
            />
            <Button startIcon={<Iconify icon="ic:baseline-download" />} variant="outlined" sx={{ mt: 1 }} onClick={() => handleDownload(src)}>
              Download Assignment
            </Button>
          </Box>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Stack direction={'column'} alignItems="center">
        <Box
          component="span"
          sx={{
            lineHeight: 0,
            display: 'block',
            overflow: 'hidden',
            cursor: 'pointer',

            '& .wrapper': { width: 1, height: 1, backgroundSize: 'cover !important' },
            ...sx
          }}
        >
          <Iconify
            sx={{ width: 1, height: 1, objectFit: 'cover' }}
            color="primary.main"
            {...other}
            onClick={() => handleDownload(src)}
            icon={'material-symbols:assignment-outline'}
          />
        </Box>
        <Button startIcon={<Iconify icon="ic:baseline-download" />} variant="outlined" sx={{ mt: 1 }} onClick={() => handleDownload(src)}>
          Download Document
        </Button>
      </Stack>
    </>
  );
}

// ----------------------------------------------------------------------

function getRatio(ratio = '1/1') {
  return {
    '4/3': 'calc(100% / 4 * 3)',
    '3/4': 'calc(100% / 3 * 4)',
    '6/4': 'calc(100% / 6 * 4)',
    '4/6': 'calc(100% / 4 * 6)',
    '16/9': 'calc(100% / 16 * 9)',
    '9/16': 'calc(100% / 9 * 16)',
    '21/9': 'calc(100% / 21 * 9)',
    '9/21': 'calc(100% / 9 * 21)',
    '1/1': '100%'
  }[ratio];
}
