import React, { useEffect, useState } from 'react';
// @mui
import { Box, Card, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
// routes
// hooks
// import useTable, { getComparator, emptyRows } from "../../hooks/useTable";
// _mock_
// components

import { TableHeadCustom } from 'src/components/table';
// sections

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { eduleyApi } from 'src/api/apiConfig';
import { PLATFORM_SETTINGS_ACCESS_REQUEST } from 'src/apiUrl';
import { setAccessRequestList } from 'src/redux/slices/configuration';
import AccessPermissionRow from 'src/sections/@dashboard/fund_management/payout/AccessPermissionRow';
import { getPageLimit, setPageLimit } from 'src/utils/pageLimitControl';

// ----------------------------------------------------------------------

// const SERVICE_OPTIONS = ["all", "Debit", "Credit"];

const ACCESS_PERMISSION_TABLE_HEAD = [
  { id: 'email', label: 'Email ID', align: 'left' },
  { id: '', label: `Request Raised For`, align: 'left' },
  { id: '', label: 'Reason', align: 'left' },
  { id: '', label: 'Raised On', align: 'left' },
  { id: '', label: 'Actions', align: 'left' }
];

const STATUS_ACCESS_PERMISSION_TABLE_HEAD = [
  { id: 'email', label: 'Email ID', align: 'left' },
  { id: '', label: `Request Raised For`, align: 'left' },
  { id: '', label: 'Reason', align: 'left' },
  { id: '', label: 'Raised On', align: 'left' },
  { id: '', label: 'Status', align: 'left' }
];

// ----------------------------------------------------------------------

export default function AccessPermission({ type, updateAccessRequestCount }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { accessRequestList } = useSelector((state) => state.configuration);
  const [pagedata, setPageData] = useState({
    limit: getPageLimit('recentpayout'),
    offset: 0,
    orderBy: 'create_date',
    order: 'desc'
  });

  const query = `?limit=${pagedata.limit}&offset=${pagedata.offset}&ordering=${pagedata.order === 'desc' ? '-' : ''}${
    pagedata.orderBy
  }&status=${type}`;

  const permissionList = React.useMemo(() => accessRequestList[type], [accessRequestList, type]);

  useEffect(() => {
    getPlatformSettingsPermissionslist(query);
  }, [pagedata]);

  const getPlatformSettingsPermissionslist = async () => {
    try {
      let res = await eduleyApi.get(`${PLATFORM_SETTINGS_ACCESS_REQUEST}${query}`);
      const accessRequestListTemp = {
        ...accessRequestList
      };
      accessRequestListTemp[type] = {...res.data};
      dispatch(setAccessRequestList(accessRequestListTemp));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrderBy = (event) => {
    setPageData({
      ...pagedata,
      orderBy: event,
      offset: 0,
      order: pagedata.orderBy === event && pagedata.order === 'desc' ? 'asc' : 'desc'
    });
  };

  const onAccessChange = async (data, permissionType) => {
    try {
      let res = await eduleyApi.patch(`${PLATFORM_SETTINGS_ACCESS_REQUEST}${data.id}/`, {
        status: permissionType
      });
      enqueueSnackbar('Status updated successfully', { variant: 'success' });
      if (updateAccessRequestCount) updateAccessRequestCount();
      getPlatformSettingsPermissionslist();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.data?.data?.error || 'Something went wrong', { variant: 'error' });
    }
  };

  return (
    <Card>
      <TableContainer
        sx={{
          position: 'relative',
          minHeight: 260
        }}
      >
        <Table>
          <TableHeadCustom
            order={pagedata.order}
            orderBy={pagedata.orderBy}
            headLabel={type === 'pending' ? ACCESS_PERMISSION_TABLE_HEAD : STATUS_ACCESS_PERMISSION_TABLE_HEAD}
            rowCount={permissionList?.results.length}
            onSort={handleOrderBy}
          />

          <TableBody>
            {permissionList?.results?.map((row, ii) => (
              <AccessPermissionRow key={ii + 'hjj'} row={row} type={type} onAccessChange={(permissionType) => onAccessChange(row, permissionType)} />
            ))}
          </TableBody>
          {permissionList?.count == 0 && (
            <TableRow style={{ height: 53 * 5 }}>
              <TableCell align="center" colSpan={9} sx={{ py: 3, color: 'text.main' }}>
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
      {/* </Scrollbar> */}

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={permissionList?.count || 0}
          rowsPerPage={pagedata.limit}
          page={pagedata.offset / pagedata.limit + 0}
          onPageChange={(event, newPage) => {
            // console.log("newPage====", newPage);
            setPageData({
              ...pagedata,
              offset: newPage * pagedata.limit
            });
          }}
          onRowsPerPageChange={(event) => {
            // console.log("event====", event.target.value);
            setPageLimit('recentpayout', event.target.value);
            setPageData({ ...pagedata, limit: event.target.value });
          }}
        />
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------
